import { RequestTracking } from "src/utils/Loadable";
import { AdminApi } from "src/clients/adminApiClient";
import { action, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import {
  GlobalSettingsDto,
  LinkDto,
  SocialLinkDto,
  LinkListDto,
  ContactLinkDto,
  PersonalCabinetDto,
} from "src/interfaces/GlobalSettingsDto";

export class GlobalSettingsPageStore extends RequestTracking {
  @observable headerTopLink: LinkDto[] = [];
  @observable headerSocialLink: SocialLinkDto[] = [];
  @observable headerBottomLink: LinkDto[] = [];

  @observable footerTopLink: LinkDto[] = [];
  @observable footerLinkList: LinkListDto[] = [];
  @observable footerContactLinkList: ContactLinkDto[] = [];
  @observable footerSocialLink: SocialLinkDto[] = [];

  @observable requestFormTitle: string = "";
  @observable requestFormLeftTitle: string = "";
  @observable requestFormRightTitle: string = "";
  @observable requestFormPostText: string = "";

  @observable catalogUniversityTitle: string = "";
  @observable catalogUniversityDescription: string = "";
  @observable catalogUniversitySearch: string = "";
  @observable catalogHousingTitle: string = "";
  @observable catalogHousingDescription: string = "";
  @observable catalogHousingSearch: string = "";
  @observable catalogCourseTitle: string = "";
  @observable catalogCourseDescription: string = "";
  @observable catalogCourseSearch: string = "";
  @observable catalogSearchButton: string = "";

  @observable knowledgeBaseTitle: string = "";
  @observable knowledgeBaseDescription: string = "";
  @observable knowledgeBaseSearchInput: string = "";
  @observable knowledgeBaseSearchButton: string = "";
  @observable knowledgeBaseDescriptionTestCard: string = "";
  @observable knowledgeBaseCurveTestCard: string = "";
  @observable knowledgeBaseButtonTestCard: string = "";
  @observable knowledgeBaseLinkTestCard: string = "";

  @observable testModalDescription: string = "";
  @observable testModalPlaceholder: string = "";
  @observable testModalButton: string = "";

  @observable alert: boolean = false;
  @observable buttonDisabled: boolean = false;
  @observable lang: string = "";
  @observable personalCabinet: PersonalCabinetDto = {};

  constructor(public rootStore: RootStore) {
    super();
  }

  @action resetState() {
    this.headerTopLink = [];
    this.headerSocialLink = [];
    this.headerBottomLink = [];
    this.footerTopLink = [];
    this.footerLinkList = [];
    this.footerContactLinkList = [];
    this.footerSocialLink = [];
    this.requestFormTitle = "";
    this.requestFormLeftTitle = "";
    this.requestFormRightTitle = "";
    this.requestFormPostText = "";
    this.catalogUniversityTitle = "";
    this.catalogUniversityDescription = "";
    this.catalogUniversitySearch = "";
    this.catalogHousingTitle = "";
    this.catalogHousingDescription = "";
    this.catalogHousingSearch = "";
    this.catalogCourseTitle = "";
    this.catalogCourseDescription = "";
    this.catalogCourseSearch = "";
    this.catalogSearchButton = "";
    this.knowledgeBaseTitle = "";
    this.knowledgeBaseDescription = "";
    this.knowledgeBaseSearchInput = "";
    this.knowledgeBaseSearchButton = "";
    this.knowledgeBaseDescriptionTestCard = "";
    this.knowledgeBaseCurveTestCard = "";
    this.knowledgeBaseButtonTestCard = "";
    this.knowledgeBaseLinkTestCard = "";
    this.testModalDescription = "";
    this.testModalPlaceholder = "";
    this.testModalButton = "";
  }

  @action async load(lang: string) {
    this.resetState();
    this.lang = lang;
    try {
      const req: GlobalSettingsDto = await this.track(() => AdminApi.getGlobalSettings(lang));
      this.headerTopLink = req.header.headerTopLink ?? [];
      this.headerSocialLink = req.header.headerSocialLink ?? [];
      this.headerBottomLink = req.header.headerBottomLink ?? [];
      this.footerTopLink = req.footer.footerTopLink ?? [];
      this.footerLinkList = req.footer.footerLinkList ?? [];
      this.footerContactLinkList = req.footer.footerContactLinkList ?? [];
      this.footerSocialLink = req.footer.footerSocialLink ?? [];
      this.requestFormTitle = req.requestForm.requestFormTitle;
      this.requestFormLeftTitle = req.requestForm.requestFormLeftTitle;
      this.requestFormRightTitle = req.requestForm.requestFormRightTitle;
      this.requestFormPostText = req.requestForm.requestFormPostText;
      this.catalogUniversityTitle = req.catalog.catalogUniversityTitle;
      this.catalogUniversityDescription = req.catalog.catalogUniversityDescription;
      this.catalogUniversitySearch = req.catalog.catalogUniversitySearch;
      this.catalogHousingTitle = req.catalog.catalogHousingTitle;
      this.catalogHousingDescription = req.catalog.catalogHousingDescription;
      this.catalogHousingSearch = req.catalog.catalogHousingSearch;
      this.catalogCourseTitle = req.catalog.catalogCourseTitle;
      this.catalogCourseDescription = req.catalog.catalogCourseDescription;
      this.catalogCourseSearch = req.catalog.catalogCourseSearch;
      this.catalogSearchButton = req.catalog.catalogSearchButton;
      this.knowledgeBaseTitle = req.knowledgeBase.knowledgeBaseTitle;
      this.knowledgeBaseDescription = req.knowledgeBase.knowledgeBaseDescription;
      this.knowledgeBaseSearchInput = req.knowledgeBase.knowledgeBaseSearchInput;
      this.knowledgeBaseSearchButton = req.knowledgeBase.knowledgeBaseSearchButton;
      this.knowledgeBaseDescriptionTestCard = req.knowledgeBase.knowledgeBaseDescriptionTestCard;
      this.knowledgeBaseCurveTestCard = req.knowledgeBase.knowledgeBaseCurveTestCard;
      this.knowledgeBaseButtonTestCard = req.knowledgeBase.knowledgeBaseButtonTestCard;
      this.knowledgeBaseLinkTestCard = req.knowledgeBase.knowledgeBaseLinkTestCard;
      this.testModalDescription = req.testModal.testModalDescription;
      this.testModalPlaceholder = req.testModal.testModalPlaceholder;
      this.testModalButton = req.testModal.testModalButton;
      this.personalCabinet = req.personalCabinet ?? {};
    } catch (e) {}
  }

  async save() {
    this.buttonDisabled = true;
    const data: GlobalSettingsDto = {
      header: {
        headerTopLink: this.headerTopLink,
        headerSocialLink: this.headerSocialLink,
        headerBottomLink: this.headerBottomLink,
      },
      requestForm: {
        requestFormLeftTitle: this.requestFormLeftTitle,
        requestFormRightTitle: this.requestFormRightTitle,
        requestFormTitle: this.requestFormTitle,
        requestFormPostText: this.requestFormPostText,
      },
      footer: {
        footerTopLink: this.footerTopLink,
        footerLinkList: this.footerLinkList,
        footerContactLinkList: this.footerContactLinkList,
        footerSocialLink: this.footerSocialLink,
      },
      catalog: {
        catalogUniversityTitle: this.catalogUniversityTitle,
        catalogUniversityDescription: this.catalogUniversityDescription,
        catalogUniversitySearch: this.catalogUniversitySearch,
        catalogHousingTitle: this.catalogHousingTitle,
        catalogHousingDescription: this.catalogHousingDescription,
        catalogHousingSearch: this.catalogHousingSearch,
        catalogCourseTitle: this.catalogCourseTitle,
        catalogCourseDescription: this.catalogCourseDescription,
        catalogCourseSearch: this.catalogCourseSearch,
        catalogSearchButton: this.catalogSearchButton
      },
      knowledgeBase: {
        knowledgeBaseTitle: this.knowledgeBaseTitle,
        knowledgeBaseDescription: this.knowledgeBaseDescription,
        knowledgeBaseSearchInput: this.knowledgeBaseSearchInput,
        knowledgeBaseSearchButton: this.knowledgeBaseSearchButton,
        knowledgeBaseDescriptionTestCard: this.knowledgeBaseDescriptionTestCard,
        knowledgeBaseCurveTestCard: this.knowledgeBaseCurveTestCard,
        knowledgeBaseButtonTestCard: this.knowledgeBaseButtonTestCard,
        knowledgeBaseLinkTestCard: this.knowledgeBaseLinkTestCard,
      },
      testModal: {
        testModalDescription: this.testModalDescription,
        testModalPlaceholder: this.testModalPlaceholder,
        testModalButton: this.testModalButton,
      },
      personalCabinet: this.personalCabinet,
    };
    try {
      await this.track(() => AdminApi.putGlobalSettings(this.lang, data));
      this.alert = true;
      alert("saved");
    } catch (e) {
      alert(e);
    }

    this.buttonDisabled = false;
  }
}
