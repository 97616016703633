import React, { useContext, useEffect } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./UniversityOrSchoolBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";
import localtionIconWhite from "../../assets/icons/location_white.svg";
import union from "../../assets/icons/union.svg";
import logo from "./logo.svg";
import { ComponentHostContext } from "../index";

export interface UniversityOrSchoolBlockElement {
    backgroundImage: number | null,
    title: string;
    logo: number | null;
    specifications: { icon: number | null; text: string; }[];
    showPartnerElement: boolean;
    partnerDescription: string;
    partnerText: string;
    shortPartnerText: string;
    locationText: string;
    showLocationText: boolean;
    whiteButtonText: string;
    whiteButtonLink: string;
    showWhiteButton: boolean;
    redButtonText: string;
    showRedButton: boolean;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const UniversityOrSchoolBlock = (props: UniversityOrSchoolBlockElement) => {

    useEffect(() => {
        const headerElement = document.getElementById("header_nav");
        const updateHeaderColor = () => {
            if (headerElement) {
                if (window.innerWidth <= 1024) {
                    headerElement.classList.add(styles.transparentBackground)
                } else {
                    headerElement.classList.remove(styles.transparentBackground)
                }
            }
        }
        updateHeaderColor()
        window.addEventListener("resize", updateHeaderColor)
        return () => {
            window.removeEventListener("resize", updateHeaderColor)
        }
    }, [])

    const cl = useContext(ComponentHostContext);
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn(styles.introEducationalBlock)} style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${props.backgroundImage})`, backgroundColor: "rgba(0, 0, 0, 0.5)", backgroundBlendMode: "overlay" }}>
            <div className="flex justify-between flex-col gap-10 lg:gap-20 lg:items-start max-w-screen-xl h-full w-full my-0 mx-auto p-4 md:p-10 lg:p-6 pt-20 lg:py-20" style={marginStyles}>
                <div className="flex flex-col justify-between w-full h-full">
                    <div className="flex justify-between">
                        <div className="md:w-8/12 lg:w-full">
                            <div className="md:flex md:flex-col-reverse lg:flex-col">
                                <h1 className={styles.title}>{props.title}</h1>
                            </div>
                            <div className={styles.specifications}>
                                {props.specifications.map((spec, index) => (
                                    <div key={index} className={styles.specification}>
                                        {spec.icon && <img src={`${ApiBaseUrl}/api/media/${spec.icon}`} width={'16px'} height={'16px'} alt={`spec-icon-${index}`} className={styles.icon} />}
                                        <span>{spec.text}</span>
                                    </div>
                                ))}
                                {props.locationText && <div className="flex-row flex lg:hidden gap-1 items-baseline">
                                    <img src={localtionIconWhite} className="" />
                                    <p className={cn(styles.locationText, "mt-0.5")}>{props.locationText}</p>
                                </div>}
                            </div>
                        </div>
                        <div className="flex flex-col justify-start mb-10 md:mb-0 md:justify-start md:items-end ">
                            {props.logo && <img src={`${ApiBaseUrl}/api/media/${props.logo}`} alt="logo" className={styles.logo} />}
                        </div>
                    </div>
                    <div className={cn({ ["flex flex-col justify-between items-start md:items-end md:mt-24 md:mb-16 md:flex-row lg:mt-36 lg:mb-2  "]: !props.showRedButton },
                        { ["flex flex-col-reverse md:flex-row items-start md:items-end justify-between md:mt-24 md:mb-2 lg:mt-36 lg:mb-2"]: props.showRedButton })}>
                        <div>
                            {props.showPartnerElement &&
                                <div className={"flex flex-row-reverse md:flex-col gap-3 md:w-3/4 "}>
                                    <p className={cn("text-left", styles.description)} >{props.partnerDescription}</p>
                                    <div className={cn("flex gap-3", styles.partnerElement)}>
                                        <img className={"hidden md:flex"} src={logo} alt="" />
                                        <p className={"hidden md:flex"}>{props.partnerText?.toUpperCase()}</p>
                                        <p className={"flex md:hidden h-3"}>{props.shortPartnerText?.toUpperCase()}</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={cn({ ["flex flex-col mb-10 md:mb-0 justify-center w-full md:w-max"]: !props.showRedButton },
                            { ["flex gap-2 lg:mt-12 flex-col mb-10 md:mb-0 w-full md:w-max"]: props.showRedButton })}>
                            {props.showWhiteButton && props.whiteButtonText && <a style={{ whiteSpace: "nowrap" }} href={props.whiteButtonLink} className={cn(styles.whiteButton, "flex gap-1 justify-center items-center")}>{props.whiteButtonText}</a>}
                            {props.showRedButton && props.showWhiteButton && props.redButtonText && <button style={{ whiteSpace: "nowrap" }} onClick={() => cl?.showContactUsFormServicePink()} className={cn(styles.redButton, "flex justify-center items-center gap-2 ")}>{props.redButtonText}<img src={union} className={styles.unionIcon} /></button>}
                            {props.locationText && <div className="flex-row items-baseline mt-3 hidden lg:flex gap-3">
                                <p className={styles.locationText}>{props.locationText}</p>
                                <img src={localtionIconWhite} className="" />
                            </div>}
                        </div>
                        {props.showRedButton && !props.showWhiteButton &&
                            <div className={cn("flex w-full md:w-2/5 lg:w-auto")}>
                                <button onClick={() => cl?.showContactUsFormServicePink()} className={cn(styles.redButton, "flex")}><span className={cn("w-full text-center")}>{props.redButtonText}</span></button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export const UniversityOrSchoolBlockInfo: TypedBlockTypeInfo<UniversityOrSchoolBlockElement> = {
    id: "UniversityOrSchoolBlock",
    name: "UniversityOrSchoolBlock",
    preview: preview,
    renderer: UniversityOrSchoolBlock,
    initialData: {
        backgroundImage: null,
        title: "string",
        logo: null,
        specifications: [{ icon: null, text: "string" }],
        showPartnerElement: false,
        partnerDescription: "",
        partnerText: "",
        shortPartnerText: "",
        locationText: "",
        showLocationText: false,
        whiteButtonText: "",
        whiteButtonLink: "#",
        showWhiteButton: false,
        redButtonText: "",
        showRedButton: false,
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            specifications: {
                fields: [
                    {
                        id: "icon",
                        type: "Custom",
                        name: "icon",
                        customType: "Image"

                    },
                    {
                        id: "text",
                        type: "String",
                        name: "Text"
                    },
                ],
            },
        },
        fields: [
            {
                id: "backgroundImage",
                type: "Custom",
                name: "Background Image",
                customType: "Image",
            },
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "logo",
                name: "Logo",
                type: "Custom",
                customType: "Image",
            },
            {
                id: "specifications",
                type: "List",
                listType: "specifications",
                name: "Specifications"
            },
            {
                id: "showPartnerElement",
                type: "CheckBox",
                name: "Show Partner Element",
            },
            {
                id: "partnerDescription",
                type: "String",
                name: "Partner Description",
            },
            {
                id: "partnerText",
                type: "String",
                name: "Partner Text",
            },
            {
                id: "shortPartnerText",
                type: "String",
                name: "Short Partner Text",
            },
            {
                id: "locationText",
                type: "String",
                name: "Location Text",
            },
            {
                id: "showLocationText",
                type: "CheckBox",
                name: "Show Location Text",
            },
            {
                id: "whiteButtonText",
                type: "String",
                name: "White Button Text",
            },
            {
                id: "whiteButtonLink",
                type: "String",
                name: "White Button Link",
            },
            {
                id: "showWhiteButton",
                type: "CheckBox",
                name: "Show White Button",
            },
            {
                id: "redButtonText",
                type: "String",
                name: "Red Button Text",
            },
            {
                id: "showRedButton",
                type: "CheckBox",
                name: "Show Red Button",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ]
    }
}
