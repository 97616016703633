interface CurvedTextProps {
  text: string;
  width: string;
  fontSize: string;
  transform?: string
}

export const CurvedText: React.FC<CurvedTextProps> = ({ text, width, fontSize, transform }) => {
  const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
  return (
    <svg width="1336" height="1506" viewBox="0 0 1336 1506" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="curveSupport"
        d="M56 1490.79C233 808.292 607 1202.79 743.5 1021.29C880 839.793 557.724 589.292 576.5 347.292C595.276 105.291 742 22.7916 850 71.2915C958 119.792 983 223.792 920 447.792C857 671.792 1052 843.291 1296.5 510.791"
        stroke="#CEE1F3"
        stroke-width={`${width}`}
        transform={`${transform}`}
      />
      <text fontSize={`${fontSize}`} fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={"#00309D"} transform={`${transform}`}>
        <textPath href="#curveSupport" textAnchor="start" >
          <tspan x="0" dy="0.2em" style={{ lineHeight: "54.6px" }} >
            {repeatedText}
          </tspan>
        </textPath>
      </text>
    </svg>
  );
};
