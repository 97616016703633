import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./AboutServiceBlock.module.css";
import cn from "classnames";
import {ApiBaseUrl} from "../../api/apiClientBase";

export interface AboutServiceBlockElement {
  title: string;
  subtitle: string;
  text: string;
  extraText: string;
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
  showImage: boolean;
  image: null | number
}

export const AboutServiceBlock = (props: AboutServiceBlockElement) => {
  const marginStyles = props.customMargin
  ? {
    marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
    marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
  }
  : {};
  return (
    <div className={cn("flex flex-col p-12 max-w-screen-xl justify-center gap-10 mx-auto mb-28 md:mb-32 lg:mb-40")} style={marginStyles} id={"aboutServiceBlock"}>
      <div className={"flex flex-col gap-7 items-center text-center"}>
        <div className={cn(styles.aboutServiceBlock__title)}>
          {props.title}
        </div>
        <div className={cn(styles.aboutServiceBlock__subtitle)}>
          {props.subtitle}
        </div>
        <div className={"flex flex-col gap-3"}>
          <div className={cn(styles.aboutServiceBlock__text)}>
            {props.text}
          </div>
          <div className={cn(styles.aboutServiceBlock__text)}>
            {props.extraText}
          </div>
        </div>
        {props.showImage &&
          <img src={`${ApiBaseUrl}/api/media/${props.image}`} alt="" className={cn(styles.workBasisBlock__img)}/>
        }
      </div>
    </div>
  )
}

export const AboutServiceBlockInfo: TypedBlockTypeInfo<AboutServiceBlockElement> = {
  id: "AboutServiceBlock",
  name: "AboutServiceBlock",
  preview: preview,
  renderer: AboutServiceBlock,
  initialData: {
    title: "string",
    subtitle: "string",
    text: "string",
    extraText: "string",
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
    showImage: false,
    image: null
  },
  definition: {
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "subtitle",
        type: "String",
        name: "Subtitle",
      },
      {
        id: "text",
        type: "String",
        name: "Text",
      },
      {
        id: "extraText",
        type: "String",
        name: "Extra Text",
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
      {
        id: "showImage",
        type: "CheckBox",
        name: "Show Image"
      },
      {
        id: "image",
        type: "Custom",
        customType: "Image",
        name: "Image",
      },
    ]
  }
}
