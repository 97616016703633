import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./ResultsWorkBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface ResultsWorkBlockElement {
    header: string;
    img: number | null;
    sideImg?: "left" | "right";
    text: string;
    listText: { title: string, text: string }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const ResultsWorkBlock: React.FC<ResultsWorkBlockElement> = ({ header, img, sideImg, text, listText, bottomMargin, topMargin, customMargin }) => {
    const flexDirectionClass = sideImg === "right" ? "lg:flex-row" : "lg:flex-row-reverse";
    const marginStyles = customMargin
    ? {
        marginTop: topMargin ? `${topMargin}px` : undefined,
        marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
    }
    : {};
    return (
        <div className={cn(style.container, "my-28 md:my-32 lg:my-40")} style={marginStyles}>
            <div className={cn(`flex flex-col-reverse items-center justify-between mx-auto w-full max-w-screen-xl ${flexDirectionClass}`)}>
                <div className={cn(style.content, "flex flex-col justify-around")}>
                    {(header || text) && <div className="w-full">
                        {header && <h2>{header}</h2>}
                        {text && <div className="mt-4">{text}</div>}
                    </div>}
                    <div className="w-full">
                        {listText && listText.map((item, index) => (
                            <div key={index}>
                                {item.title && <h3>{item.title}</h3>}
                                {item.text && <span>{item.text}</span>}
                            </div>
                        ))}
                    </div>
                </div>
                {img && <img src={`${ApiBaseUrl}/api/media/${img}`} className="w-full h-full object-cover object-center" alt="" />}
            </div>
        </div>
    )
}

export const ResultsWorkBlockInfo: TypedBlockTypeInfo<ResultsWorkBlockElement> = {
    id: "ResultsWorkBlock",
    name: "ResultsWorkBlock",
    preview: preview,
    renderer: ResultsWorkBlock,
    initialData: {
        header: "",
        img: null,
        sideImg: "right",
        text: "",
        listText: [
            {
                title: "",
                text: ""
            }
        ],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            listText: {
                fields: [
                    {
                        id: "title",
                        name: "Title",
                        type: "String",
                    },
                    {
                        id: "text",
                        name: "Text",
                        type: "String"
                    }
                ]
            }
        },
        fields: [
            {
                id: "header",
                type: "String",
                name: "Header",
            },
            {
                id: "img",
                name: "Image",
                type: "Custom",
                customType: "Image",
            },
            {
                id: "sideImg",
                name: "Side Img",
                type: "Radio",
                possibleValues: [
                    {
                        id: "left",
                        name: "Left",
                    },
                    {
                        id: "right",
                        name: "Right",
                    }
                ],
            },
            {
                id: "text",
                name: "Text",
                type: "String",
            },
            {
                id: "listText",
                name: "List Text",
                type: "List",
                listType: "listText"
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};
