import React, { useRef } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./ResidenceBlock.module.css"
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import Arrow from "../../assets/icons/white_arrow.svg"
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";

export interface ResidenceBlockElement {
  title: string,
  text: string,
  elements: {
    photo: number | null,
    name: string,
    location: string,
    price: string,
    boldTextPrice: string,
    description: string,
  }[];
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
}

export const ResidenceBlock = (props: ResidenceBlockElement) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 400;
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 400;
    }
  };

  const highlightMatch = (subtitle: string, boldtext: string): JSX.Element[] => {
    if (!boldtext) return [<span key="0">{subtitle}</span>];

    const normalizeText = (text: string): string => {
      return text.toLowerCase().replace(/\s+/g, ' ').trim();
    };

    const normalizedSubtitle = normalizeText(subtitle);
    const normalizedBoldtext = normalizeText(boldtext);

    const escapedBoldtext = normalizedBoldtext.replace(/[$()*+?.\\^|{}]/g, '\\$&');

    const regex = new RegExp(
      `(${escapedBoldtext.split(' ').join('\\s*')})`,
      "gi"
    );

    const parts = normalizedSubtitle.split(regex);
    let originalIndex = 0;

    return parts.map((part, index) => {
      const originalPart = subtitle.substring(originalIndex, originalIndex + part.length);
      originalIndex += part.length;

      return regex.test(part) ? (
        <span key={index} className={styles.newPartnersBlock__boldText}>
          {originalPart}
        </span>
      ) : (
        <span key={index}>{originalPart}</span>
      );
    });
  };
  const marginStyles = props.customMargin
    ? {
      marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
      marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
    }
    : {};

  return (
    <div className="my-12 max-w-screen-xl mx-auto" style={marginStyles}>
      <div className={styles.newPartnersBlock__title + " text-center md:mb-6 mb-3"}>{props.title}</div>
      <div className={styles.newPartnersBlock__text + " text-center md:mb-6 mb-3 w-10/12 md:w-1/2  mx-auto"}>{props.text}</div>
      <div className={"hidden lg:flex gap-5 justify-center mb-6 mt-6 mb-0"}>
        <button onClick={scrollLeft} className={styles.newPartnersBlock__arrowButton}><img src={Arrow} alt="left button" /></button>
        <button onClick={scrollRight} className={cn("transform rotate-180", styles.newPartnersBlock__arrowButton)}>
          <img src={Arrow} alt="right button" />
        </button>
      </div>
      <div ref={scrollContainerRef} className={cn("hidden md:flex justify-start max-w-screen-xl gap-8 items-stretch " +
        "w-full my-0 mx-auto py-6 px-2 md:px-8 overflow-x-auto ", styles.scrollContainer)}>
        {props.elements && props.elements.map((element, index) =>
          <div className={cn("flex flex-col h-full relative", styles.newPartnersBlock__card)} key={index}>
            <div className={styles.newPartnersBlock__counter}><span style={{ color: "black" }}>{index + 1}</span>/{props.elements.length}</div>
            <div className={"flex"}>
              <img src={`${ApiBaseUrl}/api/media/${element.photo}`} className={styles.newPartnersBlock__cardImage} alt="logo" />
              <div className={cn("flex flex-col gap-3 w-full", styles.newPartnersBlock__cardText)}>
                <div className={styles.newPartnersBlock__name}>{element.name}</div>
                <div className={styles.newPartnersBlock__text}>{element.location}</div>
                <div className={styles.newPartnersBlock__text}>{highlightMatch(element.price, element.boldTextPrice)}</div>
              </div>
            </div>
            <div className={styles.newPartnersBlock__description}>{element.description}</div>
          </div>
        )}
      </div>
      <div className={cn("flex my-8 mx-auto md:hidden")}>
        <Slider backgroundColor="white">
          {props.elements.map((element, index) => (
            <div className={cn("flex flex-col", styles.newPartnersBlock__card)} key={index}>
              <div className={"flex flex-col w-full"}>
                <img src={`${ApiBaseUrl}/api/media/${element.photo}`} className={styles.newPartnersBlock__cardImage} alt="logo" />
                <div className={cn("flex flex-col gap-3", styles.newPartnersBlock__cardText)}>
                  <div className={styles.newPartnersBlock__name}>{element.name}</div>
                  <div className={cn(styles.newPartnersBlock__text, "mb-2")}>{element.location}</div>
                  <div className={styles.newPartnersBlock__text}>{highlightMatch(element.price, element.boldTextPrice)}</div>
                </div>
              </div>
              <div className={styles.newPartnersBlock__description}>{element.description}</div>
            </div>
          ))}
        </Slider>
      </div>
    </div>

  );
};

export const ResidenceBlockInfo: TypedBlockTypeInfo<ResidenceBlockElement> = {
  id: "ResidenceBlock",
  name: "ResidenceBlock",
  preview: preview,
  renderer: ResidenceBlock,
  initialData: {
    title: "string",
    text: "string",
    elements: [{
      photo: null,
      name: "string",
      location: "string",
      price: "string",
      boldTextPrice: "",
      description: "string",
    }],
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
  },
  definition: {
    subTypes: {
      element: {
        fields: [
          {
            id: "photo",
            type: "Custom",
            customType: "Image",
            name: "Photo",
          },
          {
            id: "name",
            type: "String",
            name: "Name"
          },
          {
            id: "location",
            type: "String",
            name: "Location"
          },
          {
            id: "price",
            type: "String",
            name: "Price"
          },
          {
            id: "boldTextPrice",
            type: "String",
            name: "Bold Text Price"
          },
          {
            id: "description",
            type: "String",
            name: "Description"
          },
        ],
      },
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "text",
        type: "String",
        name: "Text",
      },
      {
        id: "elements",
        type: "List",
        listType: "element",
        name: "Elements"
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ],
  },
};
