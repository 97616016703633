interface CurvedTextProps {
  text: string;
  color: string;
}

export const CurvedText4: React.FC<CurvedTextProps> = ({ text, color }) => {
  const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
  const id = `curved${Math.random() * 100}`;

  return (
    <svg width="587" height="401" viewBox="0 0 587 401" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id={id}
        d="M568.5 326L539.589 340.772C513.568 354.068 484.764 361 455.543 361V361C434.013 361 412.65 357.236 392.417 349.879L377.922 344.608C364.04 339.56 350.914 332.634 338.911 324.023V324.023C320.509 310.821 305.084 293.903 293.634 274.363L283.911 257.768C276.982 245.942 269.095 234.694 260.34 224.149V224.149C239.972 199.614 215.132 179.143 187.156 163.843L165.5 152L122.582 127.81C114.538 123.276 106.833 118.165 99.5285 112.517L84.5216 100.914C69.7317 89.4781 57.9627 74.5995 50.2398 57.5741V57.5741C43.4912 42.6966 40 26.5486 40 10.212V0"
        stroke="white"
        stroke-width="80"
      />
      <text fontSize="38px" fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={color}>
        <textPath href={`#${id}`} textAnchor="start">
          <tspan x="0" dy="0.2em" style={{ lineHeight: "38px" }}>
            {repeatedText}
          </tspan>
        </textPath>
      </text>
    </svg>
  );
};
