import React from "react";
import style from "./LineAndImageBlock.module.css";

interface CurveTextProps {
    text: string;
    color: string;
}

export const CurveText5: React.FC<CurveTextProps> = ({ text, color }) => {
    const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
    const id = `curved${Math.random() * 100}`;

    return (
        <div className={style.svgContainer}>
            <svg width="100%" height="auto" viewBox="0 0 680 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    id={id}
                    d="M-70.1911 616.836C271.602 665.903 241.433 441.385 339.309 154.364C437.184 -132.657 676.672 -157.521 676.672 -157.521"                    
                    stroke="rgba(0, 48, 157, 1)"
                    stroke-width="115"
                />
                <text fontSize="2.625rem" fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={color}>
                    <textPath href={`#${id}`} textAnchor="start">
                        {repeatedText}
                    </textPath>
                </text>
            </svg>
        </div>
    );
};