import React from "react";
import style from "./LineAndImageBlock.module.css";

interface CurveTextProps {
    text: string;
    color: string;
}

export const CurveText9: React.FC<CurveTextProps> = ({ text, color }) => {
    const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
    const id = `curved${Math.random() * 100}`;

    return (
        <div className={style.svgContainer}>
            <svg width="100%" height="auto" viewBox="0 0 760 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    id={id}
                    d="M4 559.249C197.371 571.93 228.431 283.153 300.749 137.241C373.067 -8.67127 559.479 59.6871 617.205 238.306C674.931 416.925 498.253 465.158 426.356 602.504C354.459 739.851 402.203 787.556 289.592 864.534"                    stroke="white"
                    stroke-width="115"
                    transform="translate(-200, 50)"
                />
                <text fontSize="2.625rem" fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={color}>
                    <textPath href={`#${id}`} textAnchor="start">
                        {repeatedText}
                    </textPath>
                </text>
            </svg>
        </div>
    );
};


