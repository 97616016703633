// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImgAndSignatureBlock_video__1LeQC {\n  height: 600px;\n}\n\n.ImgAndSignatureBlock_image__3lQg6{\n  height: 570px;\n}\n\n@media (max-width: 1023px) and (min-width: 767px){\n  .ImgAndSignatureBlock_video__1LeQC{\n    height: 375px;\n  }\n\n  .ImgAndSignatureBlock_image__3lQg6{\n    height: 330px;\n  }\n}\n\n@media (max-width:767px){\n  .ImgAndSignatureBlock_video__1LeQC{\n    height: 177px;\n  }\n\n  .ImgAndSignatureBlock_image__3lQg6{\n    height: 345px;\n  }\n}", "",{"version":3,"sources":["webpack://../components/src/blocks/ImgAndSignatureBlock/ImgAndSignatureBlock.module.css"],"names":[],"mappings":"AAAA;EACI,aAAa;AACjB;;AAEA;EACI,aAAa;AACjB;;AAEA;EACI;IACI,aAAa;EACjB;;EACA;IACI,aAAa;EACjB;AACJ;;AAEA;EACI;IACI,aAAa;EACjB;;EACA;IACI,aAAa;EACjB;AACJ","sourcesContent":[".video {\n    height: 600px;\n}\n\n.image{\n    height: 570px;\n}\n\n@media (max-width: 1023px) and (min-width: 767px){\n    .video{\n        height: 375px;\n    }\n    .image{\n        height: 330px;\n    }\n}\n\n@media (max-width:767px){\n    .video{\n        height: 177px;\n    }\n    .image{\n        height: 345px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video": "ImgAndSignatureBlock_video__1LeQC",
	"image": "ImgAndSignatureBlock_image__3lQg6"
};
export default ___CSS_LOADER_EXPORT___;
