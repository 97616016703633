import React, { useState } from "react";

import cn from "classnames";

import styles from "./NewSelect.module.css";
import arrowDropdown from "../../assets/icons/arrow_dropdown.svg";

interface NewSelectProps {
  label?: string;
  value: string;
  options: { name: string; id: number | string; required?: boolean; type?: string }[];
  selectChange: (value: string, id: number | string, required?: boolean, type?: string) => void;
}

export const NewSelect = ({ label, value, options, selectChange }: NewSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={cn(styles.select, isOpen ? styles.open : "")} onClick={() => setIsOpen(!isOpen)}>
      <div>
        <span>{value}</span>
        <img
          src={arrowDropdown}
          className={cn(styles.rotated, { [styles['rotated-open']]: isOpen })}
          alt="arrow"
        />
        {options.length > 0 && (
          <>
            {isOpen && <div />}
            <ul>
              {options.map((el, ind) => (
                <li
                  key={ind}
                  onClick={() => selectChange(el.name, el.id, el?.required, el?.type)}
                  className={el.name === value ? "font-bold" : ""}
                >
                  {el.name} {el?.type}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
