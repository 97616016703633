import React, { useContext } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import NewTabs from "../../ui/newTabs/NewTabs";
import NewSearch from "../../ui/newSearch/NewSearch";
import { NewServerCatalogWidget } from "../../ui/newCatalog/newCatalogWidget";
import preview from "./preview.png";
import { ComponentHostContext } from "../index";

export interface NewTitleAndTabsBlockElement {
  title: string;
  subtitle: string;
  background: number | null;
  backgroundShadow?: string;
  tabs: { title: string; filters: { filter: string }[]; type: string }[];
}

export const NewTitleAndTabsBlock = (props: NewTitleAndTabsBlockElement) => {
  const tabsComponent = props.tabs.map((el, index) => (
    <NewServerCatalogWidget key={index + el.type} filterIds={el.filters.map((el) => el.filter)} entityType={el.type} />
  ));
  const lang = useContext(ComponentHostContext)?.lang;
  return (
    <NewSearch title={props.title} subtitle={props.subtitle} background={props.background} backgroundShadow={props.backgroundShadow}>
    <div className="lg:flex w-full">
      {/* <NewTabs titles={props.tabs?.map((el) => el.title)} components={tabsComponent} /> */}
    </div>
  </NewSearch>
  );
};

export const NewTitleAndTabsBlockInfo: TypedBlockTypeInfo<NewTitleAndTabsBlockElement> = {
  id: "newTitleAndTabsBlock",
  name: "newTitleAndTabsBlock",
  preview: preview,
  renderer: NewTitleAndTabsBlock,
  initialData: {
    title: "Quartier Latin",
    subtitle: "",
    background: null,
    backgroundShadow: "0%",
    tabs: [],
  },
  definition: {
    subTypes: {
      filtersElement: {
        fields: [
          {
            id: "filter",
            type: "Select",
            possibleValues: [
              {
                id: "city",
                name: "City",
              },
              {
                id: "instruction-language",
                name: "Instruction-language",
              },
              {
                id: "degree",
                name: "Degree",
              },
              {
                id: "placement",
                name: "Placement",
              },
              {
                id: "accreditation",
                name: "Accreditation",
              },
              {
                id: "certification",
                name: "Certification",
              },
              {
                id: "price",
                name: "Price",
              },
              {
                id: "specialty-category",
                name: "Specialty-category",
              },
            ],
            name: "filter",
          },
        ],
      },
      element: {
        fields: [
          {
            id: "title",
            type: "String",
            name: "Title",
          },
          {
            id: "filters",
            name: "Elements",
            type: "List",
            listType: "filtersElement",
          },
          {
            id: "type",
            type: "Select",
            possibleValues: [
              {
                id: "university",
                name: "university",
              },
              {
                id: "housing",
                name: "housing",
              },
              {
                id: "course",
                name: "course",
              }
            ],
            name: "type",
          },
        ],
      },
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "subtitle",
        type: "String",
        name: "Subtitle",
      },
      {
        id: "background",
        type: "Custom",
        customType: "Image",
        name: "Background",
      },
      {
        id: "backgroundShadow",
        type: "String",
        name: "Background Shadow",
      },
      {
        id: "tabs",
        name: "Tab",
        type: "List",
        listType: "element",
      },
    ],
  },
};
