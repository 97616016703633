import React from "react";
import cn from "classnames";
import styles from "./PopularServiceBlock.module.css";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface PopularServiceProps {
  title: string;
  elements: {
    imgTitle: string;
    imgText: string;
    backgroundImg: number | null;
    cardLink: string
  }[];
  firstBlockItems: { item: string }[];
  buttonText: string;
  buttonLink: string;
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
}

export const PopularServiceBlock: React.FC<PopularServiceProps> = ({ title, elements, firstBlockItems, buttonText, buttonLink, topMargin, bottomMargin, customMargin }) => {
  const marginTop = customMargin ? topMargin : '48' ;
  const marginBottom = customMargin ? bottomMargin : '48';

  return (
    <div style={{ marginTop: `${marginTop}px`, marginBottom: `${marginBottom}px` }}>
      <div className={styles.popularServiceBlock__title + " text-center md:mb-6 mb-0"}>{title}</div>
      <div className="flex flex-col justify-between lg:flex-row max-w-screen-xl gap-5 w-full my-0 mx-auto py-6 px-2 md:px-8">
        <a href={elements[0]?.cardLink || "#"} className={cn(styles.popularServiceBlock__firstItem, "lg:flex-1", styles.popularServiceBlock__card, "relative overflow-hidden")}>
          <div className={cn(styles.popularServiceBlock__cardImageWrapper, "absolute inset-0")}>
            <div
              className={cn(styles.popularServiceBlock__cardImage)}
              style={{
                backgroundImage: `url(${ApiBaseUrl}/api/media/${elements[0]?.backgroundImg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'bottom',
                width: '100%',
                height: '100%',
              }}
            />
          </div>
          <div className="flex flex-col justify-between flex-grow relative z-5">
            <div className={styles.popularServiceBlock__imageTitle} style={{ color: "white" }}>
              {elements[0]?.imgTitle}
            </div>
            <div className={styles.popularServiceBlock__imageText} style={{ color: "white" }}>
              {elements[0]?.imgText}
            </div>
            <div className="mt-7 hidden lg:flex flex-wrap gap-2" style={{ width: "330px" }}>
              {firstBlockItems && firstBlockItems.map((item) => (
                <div className={styles.popularServiceBlock__miniItems} key={item.item}>{item.item.toUpperCase()}</div>
              ))}
            </div>
          </div>
        </a>

        <div className={styles.popularServiceBlock__rightBlock + " flex flex-col gap-5 lg:flex-1"}>
          <a href={elements[1]?.cardLink || "#"} className={cn(styles.popularServiceBlock__topItem, styles.popularServiceBlock__card, "relative overflow-hidden")} style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${elements[1]?.backgroundImg})` }}>
            <div className={cn(styles.popularServiceBlock__cardImageWrapper, "absolute inset-0")}>
              <div
                className={cn(styles.popularServiceBlock__cardImage)}
                style={{
                  backgroundImage: `url(${ApiBaseUrl}/api/media/${elements[1]?.backgroundImg})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'top',
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
            <div className="flex flex-col justify-between flex-grow relative z-5">
              <div className={styles.popularServiceBlock__imageTitle}>
                {elements[1]?.imgTitle}
              </div>
              <div className={styles.popularServiceBlock__imageText + " " + styles.styleText}>
                {elements[1]?.imgText}
              </div>
            </div>
          </a>
          <div className={cn(styles.popularServiceBlock__bottomBlock, "flex flex-col md:flex-row gap-5")}>
            <a href={elements[2]?.cardLink || "#"} className={cn(styles.popularServiceBlock__videoItem, "flex-1", styles.popularServiceBlock__card, "relative overflow-hidden")}>
              <div className="flex flex-col justify-between flex-grow relative z-5">
                <div className={styles.popularServiceBlock__imageTitle}>
                  {elements[2]?.imgTitle}
                </div>
                <div className={styles.popularServiceBlock__imageText + " flex md:hidden"}>
                  {elements[2]?.imgText}
                </div>
              </div>
            </a>
            <a href={elements[3]?.cardLink || "#"} className={cn(styles.popularServiceBlock__rightItem, "flex-1", styles.popularServiceBlock__card, "relative overflow-hidden")} style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${elements[3]?.backgroundImg})` }}>
              <div className={cn(styles.popularServiceBlock__cardImageWrapper, "absolute inset-0")}>
                <div
                  className={cn(styles.popularServiceBlock__cardImage)}
                  style={{
                    backgroundImage: `url(${ApiBaseUrl}/api/media/${elements[3]?.backgroundImg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <div className="flex flex-col justify-between flex-grow relative z-5">
                <div className={styles.popularServiceBlock__imageTitle}>
                  {elements[3]?.imgTitle}
                </div>
                <div className={styles.popularServiceBlock__imageText + " flex md:hidden"}>
                  {elements[3]?.imgText}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="flex justify-center md:mt-6 mt-0">
        <a type={"button"} className={styles.popularServiceBlock__button} href={buttonLink || "#"}>
          <span>{buttonText}</span>
        </a>
      </div>
    </div>
  )
}


export const PopularServiceBlockInfo: TypedBlockTypeInfo<PopularServiceProps> = {
  id: "PopularServiceBlock",
  name: "PopularServiceBlock",
  preview: preview,
  renderer: PopularServiceBlock,
  initialData: {
    title: "string",
    buttonText: "string",
    buttonLink: "",
    elements: [
      {
        imgTitle: "string",
        imgText: "string",
        backgroundImg: null,
        cardLink: "#"
      },
    ],
    firstBlockItems: [
      {
        item: "string"
      }
    ],
    topMargin: '',
    bottomMargin: '',
    customMargin: true,
  },
  definition: {
    subTypes: {
      element: {
        fields: [
          {
            id: "imgTitle",
            name: "Image Title",
            type: "String",
          },
          {
            id: "imgText",
            name: "Image Text",
            type: "String",
          },
          {
            id: "backgroundImg",
            name: "Background Image",
            type: "Custom",
            customType: "Image",
          },
          {
            id: "cardLink",
            name: "Card Link",
            type: "String",
          },
        ],
      },
      firstBlockItem: {
        fields: [
          {
            id: "item",
            name: "Item",
            type: "String"
          }
        ]
      }
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "elements",
        name: "Elements",
        type: "List",
        listType: "element",
      },
      {
        id: "firstBlockItems",
        name: "First Block Items",
        type: "List",
        listType: "firstBlockItem",
      },
      {
        id: "buttonText",
        type: "String",
        name: "Button Text",
      },
      {
        id: "buttonLink",
        type: "String",
        name: "Button Link"
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ],
  },
};
