import React, { useState } from 'react';
import style from './Slider.module.css';


interface SliderProps {
    children: React.ReactNode[];
    backgroundColor?: string
}

export const Slider: React.FC<SliderProps> = ({ children, backgroundColor }) => {
    const dotBackgroundColor = backgroundColor === 'white' ? '#C3C3C3' : 'rgba(255, 255, 255, 0.5)';
    const activeDotBackgroundColor = backgroundColor === 'white' ? '#373737' : 'rgba(255, 255, 255, 1)';

    const [currentSlide, setCurrentSlide] = useState(0);
    const [touchStart, setTouchStart] = useState<number | null>(null);
    const [touchEnd, setTouchEnd] = useState<number | null>(null);
    const [mouseStart, setMouseStart] = useState<number | null>(null);
    const [mouseEnd, setMouseEnd] = useState<number | null>(null);

    const minSwipeDistance = 50;

    const handleTouchStart = (e: React.TouchEvent) => {
        setTouchStart(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            nextSlide();
        } else if (isRightSwipe) {
            prevSlide();
        }

        setTouchStart(null);
        setTouchEnd(null);
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        setMouseStart(e.clientX);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (mouseStart !== null) {
            setMouseEnd(e.clientX);
        }
    };

    const handleMouseUp = () => {
        if (!mouseStart || !mouseEnd) return;
        const distance = mouseStart - mouseEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            nextSlide();
        } else if (isRightSwipe) {
            prevSlide();
        }

        setMouseStart(null);
        setMouseEnd(null);
    };

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev === children.length - 1 ? 0 : prev + 1));
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev === 0 ? children.length - 1 : prev - 1));
    };

    const goToSlide = (index: number) => {
        setCurrentSlide(index);
    };

    return (
        <div
            className={style.slider}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
        >
            <div className={style.slider__content} style={{ transform: `translateX(${-currentSlide * 100}%)` }}>
                {children.map((child, index) => (
                    <div
                        key={index}
                        className={`${style.slider__element} ${index === currentSlide ? style.active : ''}`}
                    >
                        {child}
                    </div>
                ))}
            </div>
            <div className={style.slider__dots}>
                {children.map((_, index) => (
                    <span
                        key={index}
                        className={`${style.slider__dot} ${index === currentSlide ? style.active : ''}`}
                        style={{
                            backgroundColor: index === currentSlide ? activeDotBackgroundColor : dotBackgroundColor
                        }}
                        onClick={() => goToSlide(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default Slider;
