// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".newCatalogWidget_catalog_button__1gTd_ {\n  background: rgba(250, 83, 84, 1);\n  border-radius: 80px;\n  width: 100%;\n  height: 60px;\n  flex-shrink: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: 500;\n  font-size: 0.875rem;\n  padding: 17px 24px;\n  color: #fff;\n  margin-top: 0.5rem;\n}\n\n@media (min-width: 1024px){\n  .newCatalogWidget_catalog_button__1gTd_ {\n    background: rgba(250, 83, 84, 1);\n    border-radius: 80px;\n    width: 200px;\n    height: 60px;\n    flex-shrink: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-weight: 500;\n    font-size: 0.875rem;\n    padding: 17px 24px;\n    color: #fff;\n    margin: 0;\n  }\n}", "",{"version":3,"sources":["webpack://../components/src/ui/newCatalog/style/newCatalogWidget.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE;IACE,gCAAgC;IAChC,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,SAAS;EACX;AACF","sourcesContent":[".catalog_button {\n  background: rgba(250, 83, 84, 1);\n  border-radius: 80px;\n  width: 100%;\n  height: 60px;\n  flex-shrink: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: 500;\n  font-size: 0.875rem;\n  padding: 17px 24px;\n  color: #fff;\n  margin-top: 0.5rem;\n}\n\n@media (min-width: 1024px){\n  .catalog_button {\n    background: rgba(250, 83, 84, 1);\n    border-radius: 80px;\n    width: 200px;\n    height: 60px;\n    flex-shrink: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-weight: 500;\n    font-size: 0.875rem;\n    padding: 17px 24px;\n    color: #fff;\n    margin: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"catalog_button": "newCatalogWidget_catalog_button__1gTd_"
};
export default ___CSS_LOADER_EXPORT___;
