// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotFoundPage_loginBoard__2GVXv{\n  border: 1px solid black;\n  padding: 50px;\n  background: white;\n  border-radius: 6px;\n  justify-content: center;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://src/pages/NotFounds/NotFoundPage.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[".loginBoard{\n  border: 1px solid black;\n  padding: 50px;\n  background: white;\n  border-radius: 6px;\n  justify-content: center;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginBoard": "NotFoundPage_loginBoard__2GVXv"
};
export default ___CSS_LOADER_EXPORT___;
