import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./LivingBlock.module.css"
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import cn from "classnames";

export interface LivingBlockElement {
  title: string;
  text: string;
  elements: {
    icon: number | null,
    title: string,
    text: string,
    background: "red" | "white",
    button: boolean,
    buttonText?: string,
    buttonLink?: string,
  }[];
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
}

export const LivingBlock = (props: LivingBlockElement) => {
  const marginStyles = props.customMargin
    ? {
      marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
      marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
    }
    : {};
  return (
    <div className={"flex justify-center"}>
      <div className={`max-w-screen-xl p-4 my-10 md:p-12 flex justify-center align-center`} style={marginStyles}>
        <div className="flex flex-col justify-center w-full mx-auto align-center gap-8">
          <div className={cn(styles.livingBlock__title)}>{props.title}</div>
          <div className={cn(styles.livingBlock__text, "text-center mb-4 md:mb-6 lg:px-36")}>{props.text}</div>
          <div className={cn(styles.livingBlock__row)}>
            {props.elements.map((el, index) => (
              <div className={cn(el.button ? "justify-between" : "justify-start", `flex flex-col h-full p-8 lg:flex-1`)} key={index} style={{ backgroundColor: el.background === "red" ? "#FA5354" : "#EFEFEF" }}>
                <div className={"flex flex-col gap-4"} style={el.background === "red" ? { color: "white" } : { color: "#373737" }}>
                  {el.icon && <img className={styles.livingBlock__cardIcon} src={`${ApiBaseUrl}/api/media/${el.icon}`} alt="" />}
                  <p className={styles.livingBlock__cardTitle}>{el.title}</p>
                  <p className={styles.livingBlock__text}>{el.text}</p>
                </div>
                {el.button &&
                  <a href={`${el.buttonLink}` || '#'} className={cn("w-full mt-4 md:mt-0", styles.livingBlock__button)}>
                    {el.buttonText}
                  </a>
                }
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const LivingBlockInfo: TypedBlockTypeInfo<LivingBlockElement> = {
  id: "LivingBlock",
  name: "LivingBlock",
  preview: preview,
  renderer: LivingBlock,
  initialData: {
    title: "string",
    text: "string",
    elements: [
      {
        icon: null,
        title: "string",
        text: "string",
        background: "white",
        button: false,
        buttonText: "",
        buttonLink: "#",
      }
    ],
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
  },
  definition: {
    subTypes: {
      element: {
        fields: [
          {
            id: "icon",
            type: "Custom",
            customType: "Image",
            name: "Icon",
          },
          {
            id: "title",
            type: "String",
            name: "Title"
          },
          {
            id: "text",
            type: "String",
            name: "Text"
          },
          {
            id: "background",
            type: "Radio",
            possibleValues: [
              {
                id: "white",
                name: "white",
              },
              {
                id: "red",
                name: "red",
              },
            ],
            name: "Background"
          },
          {
            id: "button",
            type: "CheckBox",
            name: "Button"
          },
          {
            id: "buttonText",
            type: "String",
            name: "Button Text"
          },
          {
            id: "buttonLink",
            type: "String",
            name: "Button Link"
          },
        ],
      },
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "text",
        type: "String",
        name: "Text",
      },
      {
        id: "elements",
        type: "List",
        listType: "element",
        name: "Elements"
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ],
  },
};
