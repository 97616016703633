import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./OffersBlock.module.css";
import cn from "classnames";
import {ApiBaseUrl} from "../../api/apiClientBase";

export interface OffersBlockElement {
    title: string;
    subtitle: string;
    elements: { text: string }[];
    bottomIcon: null | number;
    bottomText: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}


export const OffersBlock = (props: OffersBlockElement) => {
    const marginStyles = props.customMargin
    ? {
        marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
        marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
    }
    : {};
    return (
        <div className={cn(style.offersBlock__body)} style={marginStyles}>
            <div className="flex flex-col items-start max-w-screen-xl w-full my-0 mx-auto pl-8 lg:px-14 gap-32">
                <div className={"flex flex-col w-full gap-8"}>
                    <div className={cn("pt-14", style.offersBlock__text)}>
                        {props.title}
                    </div>
                    <div className={cn(style.offersBlock__subtitle, "w-11/12 md:w-4/5 lg:w-4/6")}>
                        {props.subtitle}</div>
                    <ul className={cn("grid w-full lg:w-3/4", style.offersBlock__itemsContainer)}>
                        {props.elements && props.elements.map((element) =>
                            <li className={cn("ml-3", style.offersBlock__text)}>
                                {element.text}
                            </li>
                        )}
                    </ul>
                </div>
                <div className={"flex flex-col gap-5 mb-14"}>
                    <img src={`${ApiBaseUrl}/api/media/${props.bottomIcon}`} className={style.offersBlock__icon} alt=""/>
                    <div className={cn("w-10/12 md:w-3/4 lg:w-7/12", style.offersBlock__text)}>{props.bottomText}</div>
                </div>
            </div>
        </div>
    )
}

export const OffersBlockInfo: TypedBlockTypeInfo<OffersBlockElement> = {
    id: "offersBlock",
    name: "OffersBlock",
    preview: preview,
    renderer: OffersBlock,
    initialData: {
        title: "string",
        subtitle: "",
        elements: [
            {
                text: "string",
            },
        ],
        bottomIcon: null,
        bottomText: "string",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "text",
                        type: "String",
                        name: "Text"
                    },
                ],
            },
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "subtitle",
                type: "String",
                name: "Subtitle",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
            {
                id: "bottomIcon",
                type: "Custom",
                customType: "Image",
                name: "Bottom Icon"
            },
            {
                id: "bottomText",
                type: "String",
                name: "Bottom Text",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ]
    }
}
