import React from "react";

import styles from "./PartnersBlock.module.css";
import { ApiBaseUrl } from "../../api/apiClientBase";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import cn from "classnames";

export interface PartnersProps {
  title: string;
  url?: string;
  num?: number;
  whiteElements: boolean;
  elements: { img: number | null; link?: string }[];
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export const PartnersBlock: React.FC<PartnersProps> = ({ title, url, num, whiteElements, elements, headingLevel = "h2" }) => {
  const HeadingTag = headingLevel;
  return (
    <div className="py-12">
      <div className="flex flex-col max-w-screen-xl w-full my-0 mx-auto py-6 px-10">
        <HeadingTag className={styles.title}>
          {url ? (
            <a className={styles.link} href={url}>
              {title}
            </a>
          ) : (
            <>{title}</>
          )}
          {num && num > 0 ? (
            <>
              {" "}
              <span>({num})</span>
            </>
          ) : (
            ""
          )}
        </HeadingTag>
        <div className={cn("flex flex-wrap", whiteElements ? "items-stretch" : "items-center")}>
          {elements.map(({ img, link }, ind) => (
            <div className={cn(styles.partner, whiteElements ? styles.white : "")} key={ind}>
              {link ? (
                <a href={link} target="_blank">
                  <img src={`${ApiBaseUrl}/api/media/${img}`} alt="" />
                </a>
              ) : (
                <img src={`${ApiBaseUrl}/api/media/${img}`} alt="" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export const PartnersBlockInfo: TypedBlockTypeInfo<PartnersProps> = {
  id: "partnersBlock",
  name: "PartnersBlock",
  preview: preview,
  renderer: PartnersBlock,
  initialData: {
    title: "Header",
    url: "",
    num: 0,
    whiteElements: false,
    elements: [
      {
        img: null,
        link: "",
      },
    ],
  },
  definition: {
    subTypes: {
      element: {
        fields: [
          {
            id: "img",
            name: "Image",
            type: "Custom",
            customType: "Image",
          },
          {
            id: "link",
            name: "Link",
            type: "String",
          },
        ],
      },
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "url",
        type: "String",
        name: "URL",
      },
      {
        id: "num",
        type: "Number",
        name: "Num:",
      },
      {
        id: "whiteElements",
        type: "CheckBox",
        name: "White Elements:",
      },
      {
        id: "elements",
        name: "Elements",
        type: "List",
        listType: "element",
      },
    ],
  },
};
