import React, { useEffect, useRef, useState } from "react";
import { action, observable } from "mobx";
import { FilesListDto } from "src/interfaces/FilesDto";
import { FilesApi } from "src/clients/filesApiClient";
import { useObserver } from "mobx-react";
import { AdminButton } from "src/components/common/AdminButton";
import { ApiBaseUrl } from "@project/components/src/api/apiClientBase";
import { FixedSizeList as List } from "react-window";

interface IFilesStore {
  current: FilesListDto;
  currentFolder: number;
  load: (id?: number) => void;
  createFolder: (title: string) => void;
  loadFile: (data: FormData) => void;
  rename: (title: string, id: number) => void;
  delete: (id: number) => void;
  deleteMedia: (id: number) => void;
}

interface IFileLoader {
  selectMedia?: (value: number) => void;
}

export const FileLoader = ({ selectMedia }: IFileLoader) => {
  const [filesStore] = useState(() =>
    observable<IFilesStore>(
      {
        current: {
          title: null,
          parentId: null,
          directories: [],
          media: [],
          id: 0,
        },

        currentFolder: 0,

        async load(folderId) {
          const res: FilesListDto = folderId ? await FilesApi.getFolderById(folderId) : await FilesApi.getFolder();

          if (res) {
            this.current = res;
            this.currentFolder = folderId || 0;
          }
        },
        async createFolder(title) {
          const res = this.currentFolder
            ? await FilesApi.createFolder({ title, parentId: this.currentFolder })
            : await FilesApi.createFolder({ title });

          if (res) {
            this.currentFolder ? this.load(this.currentFolder) : this.load();
          }
        },
        async loadFile(data) {
          try {
            const res = await FilesApi.loadFile(data);
            if (res) {
              alert("File uploaded successfully!");
              this.currentFolder ? this.load(this.currentFolder) : this.load();
            }
          } catch (error) {
            alert("Error uploading file. Please try again.");
          }
        },
        async rename(title, id) {
          const res = await FilesApi.updateFolder(id, { title });

          if (res) {
            this.currentFolder ? this.load(this.currentFolder) : this.load();
          }
        },
        async delete(id) {
          const res = await FilesApi.deleteFolder(id);

          if (res) {
            this.currentFolder ? this.load(this.currentFolder) : this.load();
          }
        },
        async deleteMedia(id) {
          const res = await FilesApi.deleteFile(id);
          if (res) {
            this.current.media = this.current.media.filter((file) => file.id !== id);
          }
        }
      },
      {
        load: action.bound,
        createFolder: action.bound,
        rename: action.bound,
        loadFile: action.bound,
        delete: action.bound,
        deleteMedia: action.bound,
      }
    )
  );

  const [canLoad, setCanLoad] = useState(false);
  const [isFirstRun, setFirstRun] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isFirstRun) {
      filesStore.load();
    } else {
      setFirstRun(false);
    }
  }, [isFirstRun]);

  const inputFile = useRef<any>(null);
  const sortedDirectories = filesStore.current.directories.sort((a, b) => a.id - b.id);
  const sortedMedia = filesStore.current.media.sort((a, b) => b.id - a.id);

  return useObserver(() => (
    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
      <div className="m-2 flex flex-col justify-center">
        <input className="m-2" ref={inputFile} type="file" onChange={(e) => setCanLoad(!!e?.target?.files?.length)} />
        {canLoad && (
          <div className="m-2">
            <AdminButton
              color={"primary"}
              onClick={async () => {
                setIsLoading(true);
                const data = new FormData();
                data.append("UploadedFile", inputFile?.current?.files[0]);
                data.append("FileType", "Image");
                if (filesStore.currentFolder > 0) data.append("StorageFolderId", filesStore.currentFolder.toString());
                filesStore.loadFile(data);
                setIsLoading(false);
              }}
            >
              {isLoading ? "Loading..." : "Load file"}
            </AdminButton>
          </div>
        )}
        <div className="m-2">
          <AdminButton
            color={"primary"}
            onClick={() => {
              const text = prompt("New name for folder:");
              if (text) return filesStore.createFolder(text);
            }}
          >
            Create folder
          </AdminButton>
        </div>
      </div>
      <h1 className="m-4 w-full text-center font-bold">
        {filesStore.current.title === null ? "Root Folder" : filesStore.current.title}
      </h1>
      <div className="m-8 flex flex-col">
        {filesStore.current.title !== null && (
          <span
            className="cursor-pointer"
            onClick={() => filesStore.load(filesStore.current.parentId ? filesStore.current.parentId : 0)}
          >
            [..]
          </span>
        )}

        {filesStore.current.directories.length || filesStore.current.media.length ? (
          <>
            {filesStore.current.title == null &&
              <List
                height={300}
                itemCount={sortedDirectories.length}
                itemSize={50}
                width="100%"
              >
                {({ index, style }) => (
                  <div key={sortedDirectories[index].id} style={style} className="py-2 flex justify-between items-center">
                    <span className="font-bold cursor-pointer" onClick={() => filesStore.load(sortedDirectories[index].id)}>
                      {sortedDirectories[index].title}
                    </span>
                    <div className="inline-flex">
                      <div className="mr-2">
                        <AdminButton
                          color={"success"}
                          onClick={() => {
                            const text = prompt("New name for folder:");
                            if (text) return filesStore.rename(text, sortedDirectories[index].id);
                          }}
                        >
                          Rename
                        </AdminButton>
                      </div>
                      <div className="mr-2">
                        <AdminButton
                          color={"danger"}
                          onClick={() => {
                            if (confirm(`Are you sure?`)) return filesStore.delete(sortedDirectories[index].id);
                          }}
                        >
                          Delete
                        </AdminButton>
                      </div>
                    </div>
                  </div>
                )}
              </List>
            }
            <List
              height={700}
              itemCount={sortedMedia.length}
              itemSize={500}
              width="100%"
            >
              {({ index, style }) => (
                <div key={sortedMedia[index].id} style={style} className="py-2 flex justify-between items-center">
                  <span onClick={() => (selectMedia ? selectMedia(sortedMedia[index].id) : {})} className="cursor-pointer" title={sortedMedia[index].title}>
                    id = {sortedMedia[index].id}
                  </span>
                  <span>{sortedMedia[index].title}</span>
                  <img
                    onClick={() => (selectMedia ? selectMedia(sortedMedia[index].id) : {})}
                    className="mr-2 cursor-pointer"
                    src={`${ApiBaseUrl}/api/media/scaled/${sortedMedia[index].id}`}
                    alt=""
                    loading="lazy"
                    style={{ maxWidth: "300px" }}
                  />
                  <AdminButton
                    color="danger"
                    onClick={() => {
                      if (confirm(`Are you sure you want to delete the file?`)) filesStore.deleteMedia(sortedMedia[index].id);
                    }}
                  >
                    Delete
                  </AdminButton>

                </div>

              )}
            </List>
          </>
        ) : (
          "Empty folder"
        )}
      </div>
    </div >
  ));
};
