import React from "react";
import style from "./LineAndImageBlock.module.css";

interface CurveTextProps {
    text: string;
    color: string;
}

export const CurveText7: React.FC<CurveTextProps> = ({ text, color }) => {
    const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
    const id = `curved${Math.random() * 100}`;

    return (
        <div className={style.svgContainer}>
            <svg width="100%" height="auto" viewBox="0 0 700 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    id={id}
                    d="M546.484 1032C585.984 891.052 573.484 651.684 251.984 543.543C-81.516 382.547 98.4842 126.201 181.484 97.6136C371.984 32.0001 450.984 357.03 573.484 515.597C709.984 654.114 855.595 513.086 879.984 387.407C917.123 196.034 672.984 83.4698 639.484 32"                   
                    stroke="#FA5354"
                    stroke-width="115"
                    transform="translate(-450, -70)"
                />
                <text fontSize="2.625rem" fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={color}>
                    <textPath href={`#${id}`} textAnchor="start">
                        {repeatedText}
                    </textPath>
                </text>
            </svg>
        </div>
    );
};
