// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes Loading_loaderFadeIn__2dZWV {\n  0% {\n    opacity: 0;\n  }\n\n  25% {\n    opacity: 0;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n\n.Loading_loader__3H_SG {\n  text-align: center;\n  width: 100%;\n  overflow: hidden;\n  animation: Loading_fadeIn__2KY1w ease 5s;\n}\n\n.Loading_loaderSpinner__1YIlz {\n  display: inline-block;\n  text-align: center;\n  border: 16px solid #f3f3f3;\n  border-top: 16px solid #00b693;\n  border-radius: 50%;\n  width: 120px;\n  height: 120px;\n  animation: Loading_spin__JyquI 2s linear infinite;\n}\n\n@keyframes Loading_spin__JyquI {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/common/Loading.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;EACZ;;EACA;IACE,UAAU;EACZ;;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,wCAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,0BAA0B;EAC1B,8BAA8B;EAC9B,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,iDAAkC;AACpC;;AAEA;EACE;IACE,uBAAuB;EACzB;;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["@keyframes loaderFadeIn {\n  0% {\n    opacity: 0;\n  }\n  25% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n.loader {\n  text-align: center;\n  width: 100%;\n  overflow: hidden;\n  animation: fadeIn ease 5s;\n}\n\n.loaderSpinner {\n  display: inline-block;\n  text-align: center;\n  border: 16px solid #f3f3f3;\n  border-top: 16px solid #00b693;\n  border-radius: 50%;\n  width: 120px;\n  height: 120px;\n  animation: spin 2s linear infinite;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "Loading_loader__3H_SG",
	"fadeIn": "Loading_fadeIn__2KY1w",
	"loaderSpinner": "Loading_loaderSpinner__1YIlz",
	"spin": "Loading_spin__JyquI",
	"loaderFadeIn": "Loading_loaderFadeIn__2dZWV"
};
export default ___CSS_LOADER_EXPORT___;
