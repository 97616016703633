interface CurvedTextProps {
  text: string;
  color: string;
  backgroundColor: string;
}

export const CurvedText: React.FC<CurvedTextProps> = ({ text, color, backgroundColor }) => {
  const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
  return (
    <svg width="997" height="1044" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="curve"
        d="M619.5 1006L504.555 878.591C470.595 840.949 433.134 806.62 392.677 776.068L318 719.673L168.057 635.462L161.866 632.335C111.351 606.823 75.0616 559.859 63.12 504.541L61.237 495.819C52.713 456.332 59.0492 415.089 79.033 379.983V379.983C105.912 332.763 154.417 301.904 208.578 297.566L227.414 296.057C267.723 292.829 308.064 301.905 343.106 322.086L352.102 327.266C386.271 346.945 414.895 374.964 435.298 408.706L498.062 512.5L524.099 558.056C536.998 580.624 552.205 601.793 569.475 621.222V621.222C584.13 637.709 600.208 652.873 617.524 666.538L631.5 677.567L655.517 692.014C685.603 710.111 720.051 719.673 755.161 719.673V719.673C788.17 719.673 820.629 711.221 849.446 695.121L880.867 677.567L894.703 667.833C932.094 641.524 949.004 594.615 937 550.5V550.5V550.5C923.164 500.425 898.666 453.934 865.185 414.211L857 404.5L839.407 385.224C782.494 322.868 716.071 269.914 642.601 228.327L607.574 208.5L498.062 148.5L389.5 98.5L289.303 53"
        stroke={backgroundColor}
        strokeWidth="115"
        // transform="rotate(180 498.5 522)"
      />
      <text fontSize="42px" fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={color}>
        <textPath href="#curve" textAnchor="start">
          <tspan x="0" dy="0.2em" style={{ lineHeight: "54.6px" }}>
            {repeatedText}
          </tspan>
        </textPath>
      </text>
    </svg>
  );
};
