import React, { useContext } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./ServicesIntroBlock.module.css";
import cn from "classnames";
import { ComponentHostContext } from "../index";

export interface ServicesIntroBlockElement {
  title: string;
  text: string;
  buttonText: string;
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
}

export const ServicesIntroBlock = (props: ServicesIntroBlockElement) => {
  const cl = useContext(ComponentHostContext);
  const generateNumericId = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0;
    }
    return Math.abs(hash);
  };
  const handleServiceClick = (title: string,) => {
    cl?.setSelectedService({ id: generateNumericId(props.title) || 0, name: props.title });
    cl?.showContactUsFormServiceBlue("rgba(243, 215, 227, 1)");
  };
  const marginStyles = props.customMargin
    ? {
      marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
      marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
    }
    : {};
  return (
    <div className={cn("flex items-center justify-center w-full my-28 md:my-32 lg:my-40")} style={marginStyles}>
      <div className={cn(styles.servicesIntroBlock__info, "max-w-screen-xl flex flex-col lg:px-12 gap-8 md:gap-12")}>
        <div className={cn(styles.servicesIntroBlock__title, "mb-6 md:mb-10")}>
          {props.title}
        </div>
        <div className={cn(styles.servicesIntroBlock__text)}>
          {props.text}
        </div>
        <button className={cn(styles.servicesIntroBlock__button, "flex self-center lg:self-start")} type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleServiceClick(props.title);
          }}>
          {props.buttonText}
        </button>
      </div>
    </div>
  )
}

export const ServicesIntroBlockInfo: TypedBlockTypeInfo<ServicesIntroBlockElement> = {
  id: "ServicesIntroBlock",
  name: "ServicesIntroBlock",
  preview: preview,
  renderer: ServicesIntroBlock,
  initialData: {
    title: "string",
    text: "string",
    buttonText: "string",
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
  },
  definition: {
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "text",
        type: "String",
        name: "Text",
      },
      {
        id: "buttonText",
        type: "String",
        name: "Button Text",
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ]
  }
}
