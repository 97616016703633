import React, { useContext, useRef } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./AdditionalServicesListBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";
import Arrow from "../../assets/icons/white_arrow.svg";
import { Slider } from "../../ui/Slider/Slider";
import { ComponentHostContext } from "../index";

interface ServiceOption {
    icon: number | null;
    title: string;
    text: string;
    beforePrice: string;
    price: string;
    buttonText: string;
    buttonLink: string;
}

export interface AdditionalServicesListBlockElement {
    header: string;
    elements: ServiceOption[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const AdditionalServicesListBlock: React.FC<AdditionalServicesListBlockElement> = ({ header, elements, customMargin, topMargin, bottomMargin }) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const cl = useContext(ComponentHostContext);

    const handleServiceClick = (service: ServiceOption) => {
        cl?.setSelectedService({ id: service.icon || 0, name: service.title });
        cl?.showContactUsFormServiceBlue("rgba(0, 48, 157, 1)");
    };
    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 260;
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 260;
        }
    };
    const marginStyles = customMargin
        ? {
            marginTop: topMargin ? `${topMargin}px` : undefined,
            marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn(`flex flex-col items-center mx-auto w-full max-w-screen-xl mb-28 md:mb-32 lg:mb-40`, style.AdditionalServicesListBlock__container)} style={marginStyles}>
            <div className={cn(style.AdditionalServicesListBlock_header)}>
                {header}
            </div>
            <div className={"hidden lg:flex gap-5 justify-center mb-6 mt-6 mb-0"}>
                <button onClick={scrollLeft} className={style.AdditionalServicesListBlock__arrowButton}>
                    <img src={Arrow} alt="left button" />
                </button>
                <button onClick={scrollRight} className={cn("transform rotate-180", style.AdditionalServicesListBlock__arrowButton)}>
                    <img src={Arrow} alt="right button" />
                </button>
            </div>
            <div
                ref={scrollContainerRef} className={cn("hidden md:flex justify-start max-w-screen-xl gap-8" +
                    " w-full my-0 mx-auto py-6 px-2 md:px-8 overflow-x-auto", style.scrollContainer)}
            >
                {elements &&
                    elements.map((el, index) =>
                        <a href={el.buttonLink || "#"} className={cn(style.AdditionalServicesListBlock__element)}>
                            <div>
                                {el.icon && <img src={`${ApiBaseUrl}/api/media/${el.icon}`} className={"mb-6 w-6 h-6"} alt="" />}
                                <div className={cn(style.AdditionalServicesListBlock__title)}>{el.title}</div>
                                <div className={cn(style.AdditionalServicesListBlock__text)}>{el.text}</div>
                            </div>
                            <div>
                                <div className="mb-4">
                                    <span className={cn(style.AdditionalServicesListBlock__beforePrice)}>{el.beforePrice}</span>
                                    <span className={cn(style.AdditionalServicesListBlock__price)}>{el.price}</span>
                                </div>
                                <button className={cn(style.AdditionalServicesListBlock__button)}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleServiceClick(el)
                                    }}
                                    style={{
                                        zIndex: 30,
                                        position: "relative",
                                    }}
                                >{el.buttonText}</button>
                            </div>
                        </a>
                    )
                }
            </div>
            <div className={cn("flex my-8 md:hidden")}>
                <Slider backgroundColor="white">
                    {elements &&
                        elements.map((el, index) =>
                            <div className={cn(style.AdditionalServicesListBlock__element)}>
                                {el.icon && <img src={`${ApiBaseUrl}/api/media/${el.icon}`} className={"mb-6 w-6 h-6"} alt="" />}
                                <div className={cn(style.AdditionalServicesListBlock__title)}>{el.title}</div>
                                <div className={cn(style.AdditionalServicesListBlock__text)}>{el.text}</div>
                                <div className="mb-4">
                                    <span className={cn(style.AdditionalServicesListBlock__beforePrice)}>{el.beforePrice}</span>
                                    <span className={cn(style.AdditionalServicesListBlock__price)}>{el.price}</span>
                                </div>
                                <button className={cn(style.AdditionalServicesListBlock__button)}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleServiceClick(el)
                                    }}
                                    style={{
                                        zIndex: 30,
                                        position: "relative",
                                    }}
                                >{el.buttonText}</button>
                            </div>
                        )
                    }
                </Slider>
            </div>
        </div>
    )
}

export const AdditionalServicesListBlockInfo: TypedBlockTypeInfo<AdditionalServicesListBlockElement> = {
    id: "AdditionalServicesListBlock",
    name: "AdditionalServicesListBlock",
    preview: preview,
    renderer: AdditionalServicesListBlock,
    initialData: {
        header: "string",
        elements: [
            {
                icon: null,
                title: "string",
                text: "string",
                beforePrice: "string",
                price: "string",
                buttonText: "string",
                buttonLink: "string",
            },
        ],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "icon",
                        name: "Icon",
                        type: "Custom",
                        customType: "Image",
                    },
                    {
                        id: "title",
                        name: "Title",
                        type: "String",
                    },
                    {
                        id: "text",
                        name: "Text",
                        type: "String",
                    },
                    {
                        id: "beforePrice",
                        name: "Before Price",
                        type: "String",
                    },
                    {
                        id: "price",
                        name: "Price",
                        type: "String",
                    },
                    {
                        id: "buttonText",
                        name: "Button Text",
                        type: "String",
                    },
                    {
                        id: "buttonLink",
                        name: "Button Link",
                        type: "String",
                    },
                ],
            },
        },
        fields: [
            {
                id: "header",
                type: "String",
                name: "Header",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    }
}
