// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".catalogWidget_catalog_button__35yXy {\n  background: #FF6768;\n  border-radius: 3px;\n  width: 185px;\n  height: 40px;\n  flex-shrink: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: 500;\n  font-size: 0.875rem;\n  color: #fff\n}\n", "",{"version":3,"sources":["webpack://../components/src/ui/catalog/style/catalogWidget.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB;AACF","sourcesContent":[".catalog_button {\n  background: #FF6768;\n  border-radius: 3px;\n  width: 185px;\n  height: 40px;\n  flex-shrink: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: 500;\n  font-size: 0.875rem;\n  color: #fff\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"catalog_button": "catalogWidget_catalog_button__35yXy"
};
export default ___CSS_LOADER_EXPORT___;
