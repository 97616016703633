//GalleryTeachersBlock
import React, { useRef } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./GalleryTeachersBlock.module.css"
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import Arrow from "../../assets/icons/white_arrow.svg";
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";

export interface GalleryTeachersBlockElement {
    title: string,
    elements: {
        photo: number | null,
        name: string,
        description: string,
    }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const GalleryTeachersBlock = (props: GalleryTeachersBlockElement) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 260;
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 260;
        }
    };
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className="my-12" style={marginStyles}>
            <div className={styles.galleryTeachersBlock__title + " text-center md:mb-6 mb-3"}>{props.title}</div>
            <div className={"hidden lg:flex gap-5 justify-center mb-6 mt-6 mb-0"}>
                <button onClick={scrollLeft} className={styles.galleryTeachersBlock__arrowButton}><img src={Arrow} alt="left button" /></button>
                <button onClick={scrollRight} className={cn("transform rotate-180", styles.galleryTeachersBlock__arrowButton)}>
                    <img src={Arrow} alt="right button" />
                </button>
            </div>
            <div ref={scrollContainerRef} className={cn("hidden md:flex justify-start max-w-screen-xl gap-8 " +
                "w-full my-0 mx-auto py-6 px-2 md:px-8 overflow-x-auto", styles.scrollContainer)}>
                {props.elements && props.elements.map((element, index) =>
                    <div className={styles.galleryTeachersBlock__card} key={index}>
                        <img src={`${ApiBaseUrl}/api/media/${element.photo}`} className={styles.galleryTeachersBlock__cardImage} alt="photo" />
                        <div className={"flex flex-col gap-3"}>
                            <div className={styles.galleryTeachersBlock__name}
                                style={element.name.length >= 40 ? { width: "100%" } : { width: "50%" }}>{element.name}</div>
                            <div className={styles.galleryTeachersBlock__description}>{element.description}</div>
                        </div>
                    </div>
                )}
            </div>
            <div className={cn("flex my-8 mx-auto md:hidden")}>
                <Slider backgroundColor="white">
                    {props.elements.map((element, index) => (
                        <div className={styles.galleryTeachersBlock__card + " mx-auto my-0"} key={index}>
                            <img src={`${ApiBaseUrl}/api/media/${element.photo}`} className={styles.galleryTeachersBlock__cardImage} alt="photo" />
                            <div className={"flex flex-col gap-3 w-full"}>
                                <div className={styles.galleryTeachersBlock__name}>{element.name}</div>
                                <div className={styles.galleryTeachersBlock__description}>{element.description}</div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>

    );
};

export const GalleryTeachersBlockInfo: TypedBlockTypeInfo<GalleryTeachersBlockElement> = {
    id: "GalleryTeachersBlock",
    name: "GalleryTeachersBlock",
    preview: preview,
    renderer: GalleryTeachersBlock,
    initialData: {
        title: "string",
        elements: [{
            name: "string",
            description: "string",
            photo: null,
        }],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "name",
                        type: "String",
                        name: "Name"
                    },
                    {
                        id: "description",
                        type: "String",
                        name: "Description"
                    },
                    {
                        id: "photo",
                        type: "Custom",
                        customType: "Image",
                        name: "Photo",
                    },
                ],
            },
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};
