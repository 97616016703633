import React, { useContext, useEffect, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./ServicePackagesBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";
import Checkbox from "../../assets/icons/checkbox.svg";
import { ComponentHostContext } from "../index";
import Arrow from "../../assets/icons/arrow_dropdown_blue.svg";

export interface ServicePackagesBlockElement {
    page: string;
    header: string;
    title: string;
    highlightedText: string;
    subtitle: string;
    description: string;
    packages: {
        type: string;
        icon: number | null;
        info: {
            price: string;
            description: string;
        }[];
        price: string;
        hours: string;
        buttonText: string;
        backgroundColor: string;
        services: {
            service: string;
            servicePrice: string;
            activeService: boolean;
        }[];
    }[];
    showMoreText: string;
    showLessText: string;
    footnote: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

const normalizeText = (text: string): string => {
    return text.toLowerCase().replace(/\s+/g, ' ').trim();
};

const highlightMatch = (subtitle: string, redtext: string): JSX.Element[] => {
    if (!redtext) return [<span key="0">{subtitle}</span>];
    const normalizedSubtitle = normalizeText(subtitle);
    const normalizedRedtext = normalizeText(redtext);
    const regex = new RegExp(`(${normalizedRedtext.split(' ').join('\\s*')})`, "gi");
    const parts = normalizedSubtitle.split(regex);
    let originalIndex = 0;
    return parts.map((part, index) => {
        const originalPart = subtitle.substring(originalIndex, originalIndex + part.length);
        originalIndex += part.length;
        return regex.test(part) ? (
            <span key={index} style={{ backgroundColor: "rgba(206, 225, 243, 1)", color: "rgba(0, 48, 157, 1)" }}>
                {originalPart}
            </span>
        ) : (
            <span key={index}>{originalPart}</span>
        );
    });
};

export const ServicePackagesBlock: React.FC<ServicePackagesBlockElement> = ({
    page,
    header,
    title,
    highlightedText,
    subtitle,
    description,
    packages,
    showMoreText,
    showLessText,
    footnote,
    customMargin,
    topMargin,
    bottomMargin
}) => {
    const [showAll, setShowAll] = useState(false);
    const [showInfo, setShowInfo] = useState<number | null>(null);
    const [isTablet, setIsTablet] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsTablet(window.innerWidth >= 768 && window.innerWidth <= 1024);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleIconClick = (index: number) => {
        setShowInfo(showInfo === index ? null : index);
    };
    const handleShowMore = () => {
        setShowAll(!showAll);
        if (showAll) {
            const targetElement = document.getElementById("topPackageService");
            if (targetElement && window.innerWidth > 767) {
                const offsetTop = targetElement.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                    top: offsetTop,
                    behavior: "auto",
                });
            }
        }
    };


    const packageCount = packages.length;
    const cl = useContext(ComponentHostContext);
    const marginStyles = customMargin
        ? {
            marginTop: topMargin ? `${topMargin}px` : undefined,
            marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn("my-12 flex flex-col items-center justify-between max-w-screen-xl w-full gap-5 mx-auto px-2 md:px-8")} style={marginStyles} id={"topPackageService"} >
            <h2 className={style.header}>{header}</h2>
            <h3 className={style.title}><span>{highlightMatch(title, highlightedText)}</span></h3>
            <p className={style.subtitle}>{subtitle}</p>
            <p className={cn(style.description, "mb-12")}>{description}</p>
            <div
                className={cn(
                    packageCount === 3
                        ? style.packages3
                        : packageCount === 2
                            ? style.packages2
                            : packageCount === 1
                                ? style.packages1
                                : style.packages4
                )}
            >
                {packages.map((pkg, index) => (
                    <div key={index} className={cn(pkg.backgroundColor === 'blue' ? style.firstOnMobile : "")}>
                        <div className={cn(style.packageCardTop, showInfo === index ? "px-7 lg:px-4 py-7" : "p-7", pkg.backgroundColor === "blue" ? style.blueCard : style.packageCardTop, pkg.backgroundColor === 'blue' ? style.raiseCard : "")}
                            style={{ height: pkg.hours ? "" : "auto" }}>
                            {showInfo === index ? (
                                <div className={cn("flex flex-col w-full items-center md:items-start lg:items-center md:flex-row lg:flex-col")}>
                                    <div className="w-full md:w-1/2 lg:w-full">
                                        <div className={cn("flex flex-row justify-center md:justify-start lg:justify-center")}>
                                            <h4 className={cn("mr-1", style.type)}>{pkg.type.toUpperCase()}</h4>
                                            {pkg.icon &&
                                                <div className={style.type}>
                                                    <img src={`${ApiBaseUrl}/api/media/${pkg.icon}`} width={16} height={16} alt=""
                                                        onClick={() => handleIconClick(index)}
                                                        style={{ cursor: "pointer" }} />
                                                </div>
                                            }
                                        </div>
                                        <div className={cn("flex justify-start h-full w-full", isTablet ? "flex-row" : "flex-col")}>
                                            {isTablet && (
                                                <div className={cn("flex w-full flex-col lg:flex-col md:items-baseline lg:items-center", pkg.price.includes("€") ? "md:flex-row" : "")}>
                                                    <p className={cn(style.price)}>{pkg.price}</p>
                                                    <p className={cn(style.hours)}>{pkg.hours}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={cn("w-full md:w-1/2 lg:w-full md:flex md:flex-col md:h-28 lg:h-auto md:justify-end")}>
                                        {pkg.info.map((infoItem, i) => (
                                            <div key={i} className={cn("flex", isTablet ? "flex-row items-end gap-4 mb-1" : "flex-col")}>
                                                <p className={cn(style.infoPrice)}>{infoItem.price}</p>
                                                <p className={cn(style.infoDescription)}>{infoItem.description}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-col w-full items-center md:items-start lg:items-center">
                                    <div className={cn("flex flex-row")}>
                                        <h4 className={cn("pr-2 mr-1", style.type)}><span className="flex items-baseline">{pkg.type.toUpperCase()}</span></h4>
                                        {pkg.icon &&
                                            <div className={style.type}>
                                                <img src={`${ApiBaseUrl}/api/media/${pkg.icon}`} width={16} height={16} alt=""
                                                    onClick={() => handleIconClick(index)}
                                                    style={{ cursor: "pointer" }} />
                                            </div>
                                        }
                                    </div>
                                    <div className={cn("flex flex-col items-center md:items-end lg:items-center w-full md:flex-row lg:flex-col", pkg.backgroundColor === 'blue' ? style.raiseCardTop : '')}>
                                        <div className={cn("flex w-full flex-col lg:flex-col md:items-baseline lg:items-center", pkg.price.includes("€") ? "md:flex-row" : "")}>
                                            <p className={cn(style.price, !pkg.price.includes("€") ? style.free : "")}>{pkg.price}</p>
                                            {pkg.hours && <p className={cn(style.hours)}>{pkg.hours}</p>}
                                        </div>
                                        <button className={cn(pkg.backgroundColor === "blue" ? style.redButton : style.defaultButton, pkg.hours ? "" : "w-full mt-5")} onClick={() => cl?.showContactUsForm()}>
                                            {pkg.buttonText}
                                        </button>

                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={cn(pkg.backgroundColor === "blue" ? style.blueServiceList : style.serviceList)}>
                            {pkg.services.slice(0, (!showMoreText && !showLessText) ? pkg.services.length : (showAll ? pkg.services.length : 5)).map((el, i) => (
                                <div key={i} className={cn(`flex flex-row`, el.servicePrice || page === "visaSupport" ? `justify-between items-center ${style.serviceItem}` : "")}>
                                    {!el.servicePrice && el.activeService && page !== "visaSupport" && <img src={Checkbox} width={16} height={16} alt="" />}
                                    <div className={cn(el.activeService ? style.service : style.serviceNonActive, el.servicePrice ? "w-10/12" : "w-full")}>{el.service}</div>
                                    {el.servicePrice && <div className={cn("whitespace-nowrap", style.servicePrice)}>{el.servicePrice}</div>}
                                </div>
                            ))}
                            {pkg.services.length > 5 && showMoreText && showLessText && (
                                <div className="flex flex-row items-center mt-4">
                                    <button className={style.showMoreButton} onClick={handleShowMore}>
                                        {showAll ? showLessText : showMoreText}
                                    </button>
                                    <img src={Arrow} width={16} height={16} alt="" className={showAll ? style.rotate : style.rotateBack} />
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {footnote && <p className={style.footnote}><span style={{ color: "rgba(0, 48, 157, 1)" }}>* </span>{footnote}</p>}
        </div>
    );
};

export const ServicePackagesBlockInfo: TypedBlockTypeInfo<ServicePackagesBlockElement> = {
    id: "ServicePackagesBlock",
    name: "ServicePackagesBlock",
    preview: preview,
    renderer: ServicePackagesBlock,
    initialData: {
        page: "visaSupport",
        header: "",
        title: "",
        highlightedText: "",
        subtitle: "",
        description: "",
        packages: [
            {
                type: "",
                icon: null,
                info: [
                    {
                        price: "",
                        description: "",
                    }
                ],
                price: "",
                hours: "",
                buttonText: "",
                backgroundColor: "light blue",
                services: [
                    {
                        service: "",
                        servicePrice: "",
                        activeService: true,
                    }
                ],
            },
        ],
        showMoreText: "",
        showLessText: "",
        footnote: "",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            info: {
                fields: [
                    {
                        id: "price",
                        name: "price",
                        type: "String",
                    },
                    {
                        id: "description",
                        name: "Description",
                        type: "String",
                    }
                ]
            },
            services: {
                fields: [
                    {
                        id: "service",
                        type: "String",
                        name: "Service",
                    },
                    {
                        id: "servicePrice",
                        type: "String",
                        name: "Service price",
                    },
                    {
                        id: "activeService",
                        type: "CheckBox",
                        name: "Active Service",
                    }
                ]
            },
            packages: {
                fields: [
                    {
                        id: "type",
                        type: "String",
                        name: "Type",
                    },
                    {
                        id: "icon",
                        type: "Custom",
                        name: "Icon",
                        customType: "Image"
                    },
                    {
                        id: "info",
                        type: "List",
                        name: "Info",
                        listType: "info"
                    },
                    {
                        id: "price",
                        type: "String",
                        name: "Price",
                    },
                    {
                        id: "hours",
                        type: "String",
                        name: "Hours",
                    },
                    {
                        id: "buttonText",
                        type: "String",
                        name: "Button Text"
                    },
                    {
                        id: "backgroundColor",
                        type: "Radio",
                        name: "Background Color",
                        possibleValues: [
                            {
                                id: "blue",
                                name: "blue",
                            },
                            {
                                id: "light blue",
                                name: "light blue",
                            },
                        ],
                    },
                    {
                        id: "services",
                        name: "Services",
                        type: "List",
                        listType: "services",
                    },
                ],
            }
        },
        fields: [
            {
                id: "page",
                type: "Radio",
                name: "Page",
                possibleValues: [
                    {
                        id: "visaSupport",
                        name: "Visa Support Page",
                    },
                    {
                        id: "onSiteAdaptation",
                        name: "On Site Adaptation",
                    },
                    {
                        id: "helpWithDocuments",
                        name: "Help With Documents",
                    },
                ],
            },
            {
                id: "header",
                type: "String",
                name: "Header",
            },
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "highlightedText",
                type: "String",
                name: "Highlighted Text"
            },
            {
                id: "subtitle",
                type: "String",
                name: "Subtitle",
            },
            {
                id: "description",
                type: "String",
                name: "Description",
            },
            {
                id: "packages",
                name: "Packages",
                type: "List",
                listType: "packages"
            },
            {
                id: "showMoreText",
                name: "Show more text",
                type: "String",
            },
            {
                id: "showLessText",
                name: "Show Less Text",
                type: "String",
            },
            {
                id: "footnote",
                name: "Footnote",
                type: "String",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};
