// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttonFormBlock_button__2eTQh {\n  padding: 11px 27px 12px 28px;\n  white-space: nowrap;\n  background: #FF6768;\n  border-radius: 3px;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 17px;\n  text-align: center;\n  color: #FFFFFF;\n  transition: 0.3s;\n  cursor: pointer;\n  border: 0;\n  display: flex;\n  align-items: center;\n  z-index: 20;\n}\n\n.buttonFormBlock_button__2eTQh img {\n  height: 20px;\n  width: 20px;\n  box-sizing: content-box;\n  padding-right: 16px;\n  margin-right: 19px;\n  margin-left: -10px;\n  padding-top: 4px;\n  padding-bottom: 3px;\n  border-right: 1px solid rgba(255, 255, 255, 0.12);\n}\n\n.buttonFormBlock_button__2eTQh:hover{\n  background: #fd7777;\n}\n\n", "",{"version":3,"sources":["webpack://../components/src/blocks/ButtonFormBlock/buttonFormBlock.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,iDAAiD;AACnD;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".button {\n  padding: 11px 27px 12px 28px;\n  white-space: nowrap;\n  background: #FF6768;\n  border-radius: 3px;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 17px;\n  text-align: center;\n  color: #FFFFFF;\n  transition: 0.3s;\n  cursor: pointer;\n  border: 0;\n  display: flex;\n  align-items: center;\n  z-index: 20;\n}\n\n.button img {\n  height: 20px;\n  width: 20px;\n  box-sizing: content-box;\n  padding-right: 16px;\n  margin-right: 19px;\n  margin-left: -10px;\n  padding-top: 4px;\n  padding-bottom: 3px;\n  border-right: 1px solid rgba(255, 255, 255, 0.12);\n}\n\n.button:hover{\n  background: #fd7777;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "buttonFormBlock_button__2eTQh"
};
export default ___CSS_LOADER_EXPORT___;
