// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectedBlock_text__1pJTq {\n  background-color: rgba(239, 239, 239, 1);\n  padding: 30px;\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 21.6px;\n  text-align: left;\n}", "",{"version":3,"sources":["webpack://../components/src/blocks/SelectedBlock/SelectedBlock.module.css"],"names":[],"mappings":"AAAA;EACI,wCAAwC;EACxC,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AACpB","sourcesContent":[".text {\n    background-color: rgba(239, 239, 239, 1);\n    padding: 30px;\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 21.6px;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "SelectedBlock_text__1pJTq"
};
export default ___CSS_LOADER_EXPORT___;
