import { ApiClientBase } from "./apiClientBase";

export type RequestFormType = {
    name: string;
    email: string;
    tel: string;
    contactWay?: string;
    com?: string;
};

export class BlockApiClient extends ApiClientBase {
    async sendConsultationRequest(req: RequestFormType) {
        const url = `call/request`;
        return await this.sendRequest(url, req);
    }
}