import styles from "./OverlayDialog.module.css";
import { Portal } from "../../utils/Portal";
import cn from "classnames";
import React from "react";
import { useEffect, useState } from "react";
import iconClose from "../../assets/icons/cross-remove.svg";

export const OverlayDialog = (props: {
  children?: any;
  cancel: () => void;
  className?: string;
  paddingBottom?: string;
  scrollable?: boolean;
  backgroundColor?: string;
}) => {
  const isDarkBackground = props.backgroundColor === "rgba(0, 48, 157, 1)";
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 10);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Portal>
      <div className={cn(styles.overlayDialogOverlay, { [styles.visible]: isVisible })} onClick={(_) => props.cancel()}>
        <div
          className={cn(styles.overlayDialogContainer, "pt-10 px-12", ` ${props.className} ${props.paddingBottom ?? "lg:pb-11"}`, { [styles.visible]: isVisible })}
          style={{ backgroundColor: props.backgroundColor,  color: isDarkBackground ? "white" : "black"}}
          onClick={(e) => e.stopPropagation()}
        >
          <button  className={styles.overlayDialogContainer__buttonClose}
            style={{ filter: isDarkBackground ? "brightness(0.7) invert(1)" : "none" }}
            onClick={(_) => props.cancel()}>
            <img src={iconClose} alt=""/>
          </button>
          <div className={`${styles.overlayDialogContent} ${props.scrollable ? "" : styles.scrollable}`}>
            {props.children}
          </div>
        </div>
      </div>
    </Portal>
  );
};
