// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".newButtonFormBlock_button__2W-1U {\n  padding: 17px 24px;\n  white-space: nowrap;\n  background: #FA5354;\n  border-radius: 80px;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 17px;\n  text-align: center;\n  color: #FFFFFF;\n  transition: 0.3s;\n  cursor: pointer;\n  border: 0;\n  display: flex;\n  align-items: center;\n  z-index: 20;\n}\n\n.newButtonFormBlock_button__2W-1U img {\n  height: 16px;\n  width: 16px;\n  box-sizing: content-box;\n  margin-right: 8px;\n  padding-top: 4px;\n  padding-bottom: 3px;\n}\n\n.newButtonFormBlock_button__2W-1U:hover{\n  background: #fd7777;\n}\n\n", "",{"version":3,"sources":["webpack://../components/src/blocks/NewButtonFormBlock/newButtonFormBlock.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".button {\n  padding: 17px 24px;\n  white-space: nowrap;\n  background: #FA5354;\n  border-radius: 80px;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 17px;\n  text-align: center;\n  color: #FFFFFF;\n  transition: 0.3s;\n  cursor: pointer;\n  border: 0;\n  display: flex;\n  align-items: center;\n  z-index: 20;\n}\n\n.button img {\n  height: 16px;\n  width: 16px;\n  box-sizing: content-box;\n  margin-right: 8px;\n  padding-top: 4px;\n  padding-bottom: 3px;\n}\n\n.button:hover{\n  background: #fd7777;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "newButtonFormBlock_button__2W-1U"
};
export default ___CSS_LOADER_EXPORT___;
