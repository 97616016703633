import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./TrainingPlanBlock.module.css";
import cn from "classnames";

export interface TrainingPlanBlockElement {
    title: string;
    semesters: {
        title: string;
        elements: {
            text: string;
        }[]
    }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const TrainingPlanBlock: React.FC<TrainingPlanBlockElement> = ({ title, semesters, customMargin, topMargin, bottomMargin }) => {
    const marginStyles = customMargin
        ? {
            marginTop: topMargin ? `${topMargin}px` : undefined,
            marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn("my-24 mx-auto w-full max-w-screen-xl")} style={marginStyles}>
            <h2 className={styles.trainingPlanTitle}>{title}</h2>
            <div className={styles.semestersContainer}>
                {semesters.map((semester, index) => (
                    <div key={index} className={styles.semesterBlock}>
                        <h3 className={styles.semesterTitle}>{semester.title}</h3>
                        <ul className={styles.courseList}>
                            {semester.elements.map((element, idx) => (
                                <li key={idx} className={styles.courseItem}>{element.text}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const TrainingPlanBlockInfo: TypedBlockTypeInfo<TrainingPlanBlockElement> = {
    id: "TrainingPlanBlock",
    name: "TrainingPlanBlock",
    preview: preview,
    renderer: TrainingPlanBlock,
    initialData: {
        title: "",
        semesters: [
            {
                title: "",
                elements: [{
                    text: "",
                }]
            },
        ],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "text",
                        type: "String",
                        name: "Text",
                    }
                ]
            },
            semester: {
                fields: [
                    {
                        id: "title",
                        type: "String",
                        name: "Title",
                    },
                    {
                        id: "elements",
                        name: "Elements",
                        type: "List",
                        listType: "element",
                    },
                ]
            }
        },
        fields: [
            {
                id: "title",
                name: "Title",
                type: "String",
            },
            {
                id: "semesters",
                name: "Semesters",
                type: "List",
                listType: "semester",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};