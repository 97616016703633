import styles from "./AdminOverlayDialog.module.css";
import { Portal } from "@project/components/src/utils/Portal";
import React, { useContext } from "react";

const OverlayDialogZIndex = React.createContext(8000);


const AdminOverlayDialogRenderer = (props: { children?: any; cancel: () => void, isEditor?: boolean }) => {
  const currentIndex = useContext(OverlayDialogZIndex);
  const overlayClass = props.isEditor ? styles.overlayDialogEditor : styles.overlayDialogOverlay;
  const containerClass = props.isEditor ? styles.overlayDialogEditorContainer : styles.overlayDialogContainer;
  return (
    <Portal>
      <div style={{ zIndex: currentIndex }} className={overlayClass} onClick={(_) => props.cancel()} />
      <div
        style={{ zIndex: currentIndex + 1 }}
        className={styles.overlayDialogContainerMargin}
        onClick={(_) => props.cancel()}
      >
        <div className={containerClass} onClick={(e) => e.stopPropagation()}>
          <button className={styles.closeButton} onClick={(_) => props.cancel()}>
            &times;
          </button>
          <OverlayDialogZIndex.Provider value={currentIndex + 2}>{props.children}</OverlayDialogZIndex.Provider>
        </div>
      </div>
    </Portal>
  );
};

export const AdminOverlayDialog = (props: { children?: any; cancel: () => void, isEditor?: boolean }) => {
  return <AdminOverlayDialogRenderer {...props} />;
};
