// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".article-before-image-block_articleBeforeImageBlock__title__1ftVR{\n  font-weight: bold;\n  font-size: 30px;\n  line-height: 42px;\n  color: #373737;\n  margin-bottom: 14px;\n}\n\n@media (max-width: 1023px) {\n  .article-before-image-block_articleBeforeImageBlock__title__1ftVR{\n    margin-bottom: 16px;\n    text-align: center;\n    font-size: 24px;\n    line-height: 32px;\n  }\n}\n\n.article-before-image-block_articleBeforeImageBlock__text__3XHOu p{\n  font-size: 14px;\n  line-height: 24px;\n  color: #373737;\n  margin-bottom: 21px;\n}\n", "",{"version":3,"sources":["webpack://../components/src/blocks/ArticleBeforeImageBlock/article-before-image-block.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE;IACE,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;EACnB;AACF;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".articleBeforeImageBlock__title{\n  font-weight: bold;\n  font-size: 30px;\n  line-height: 42px;\n  color: #373737;\n  margin-bottom: 14px;\n}\n\n@media (max-width: 1023px) {\n  .articleBeforeImageBlock__title{\n    margin-bottom: 16px;\n    text-align: center;\n    font-size: 24px;\n    line-height: 32px;\n  }\n}\n\n.articleBeforeImageBlock__text p{\n  font-size: 14px;\n  line-height: 24px;\n  color: #373737;\n  margin-bottom: 21px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"articleBeforeImageBlock__title": "article-before-image-block_articleBeforeImageBlock__title__1ftVR",
	"articleBeforeImageBlock__text": "article-before-image-block_articleBeforeImageBlock__text__3XHOu"
};
export default ___CSS_LOADER_EXPORT___;
