import React from "react";
import style from "./LineAndImageBlock.module.css";

interface CurveTextProps {
    text: string;
    color: string;
}

export const CurveText6: React.FC<CurveTextProps> = ({ text, color }) => {
    const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
    const id = `curved${Math.random() * 100}`;

    return (
        <div className={style.svgContainer}>
            <svg width="100%" height="auto" viewBox="0 0 680 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    id={id}
                    d="M58.4216 97.0016C58.4216 145.502 58.4216 185.5 180 323.5C272 432.001 214.422 661.501 364.422 714.001C599.422 783.501 771.182 637.412 592.424 432.001C340.924 143.002 455.923 55.0017 469.923 35.5017"                    
                    stroke="#00309D"                    
                    stroke-width="115"
                    transform="translate(150, -150)"
                />
                <text fontSize="2.625rem" fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={color}>
                    <textPath href={`#${id}`} textAnchor="start">
                        {repeatedText}
                    </textPath>
                </text>
            </svg>
        </div>
    );
};

