interface CurvedTextProps {
  text: string;
  color: string;
}

export const CurvedText2: React.FC<CurvedTextProps> = ({ text, color }) => {
  const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
  const id = `curved${Math.random() * 100}`;

  return (
    <svg width="611" height="775" viewBox="0 0 715 367" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id={id}
        d="M474 711L444.936 715.291C415.035 719.706 384.522 717.283 355.692 708.204V708.204C325.017 698.544 297.126 681.626 274.385 658.885L270.626 655.126C257.95 642.45 247.281 627.913 238.988 612.019V612.019C231.367 597.412 225.831 581.808 222.543 565.664L219.326 549.875C210.236 505.25 190.787 463.385 162.546 427.657L139.5 398.5L91.5239 331.964C77.9802 313.181 68.3602 291.861 63.2382 269.278L61.8732 263.259C53.5711 226.654 58.7591 188.283 76.4836 155.197V155.197C83.1306 142.79 91.4278 131.34 101.149 121.16L117.276 104.272C131.909 88.9496 149.767 77.0743 169.557 69.5075L185.938 63.2442C200.033 57.8549 215.352 56.502 230.173 59.3374V59.3374C252.378 63.5853 271.804 76.9022 283.774 96.0808L299.654 121.525C305.863 131.474 310.883 142.118 314.609 153.238L340 229L346.378 253.368C355.102 286.697 367.307 319.016 382.791 349.792L396.155 376.353C398.382 380.778 400.94 385.029 403.807 389.068V389.068C408.254 395.335 413.419 401.058 419.199 406.122L423.004 409.456C448.786 432.046 481.898 444.5 516.177 444.5H517.094C538.109 444.5 558.869 439.894 577.913 431.007L586.5 427"
        stroke="white"
        stroke-width="115"
      />
      <text fontSize="42px" fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={color}>
        <textPath href={`#${id}`} textAnchor="start">
          {repeatedText}
        </textPath>
      </text>
    </svg>
  );
};
