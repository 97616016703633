interface MiniCurvedTextProps {
    text: string;
}

export const StretchedMiniCurvedText: React.FC<MiniCurvedTextProps> = ({ text }) => {
    const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0" }`.repeat(10);
    return (
        <svg width="1100" height="1100" viewBox="0 0 826 851" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                id="miniCurvePayment"
                d="M11.8966 427.025C61.6638 412.903 249.732 454.376 323.688 629.028C397.644 803.679 577.618 901.224 676.524 693.108C775.43 484.993 417.346 399.944 300.525 306.367C183.705 212.79 281.677 58.8265 453.392 42.0559C625.108 25.2854 633.735 193.394 742.947 284.912C756.189 296.009 795.464 317.023 824.077 318.026"
                stroke="#FA5354"
                stroke-width="80"
            />
            <text fontSize="38px" fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill="white">
                <textPath href="#miniCurvePayment" startOffset="0">
                    <tspan x="0" dy="0.2em" style={{ lineHeight: '38px' }}>
                        {repeatedText}
                    </tspan>
                </textPath>
            </text>
        </svg>
    );
};
