import React, { useRef } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./PhotoGalleryBlock.module.css";
import Arrow from "../../assets/icons/white_arrow.svg";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";
import Slider from "../../ui/Slider/Slider";

export interface PhotoGalleryBlockElement {
    title: string;
    subtitle: string;
    text: string;
    extraText: string;
    elements: {
        videoLink: string;
        image: number | null;
    }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const PhotoGalleryBlock: React.FC<PhotoGalleryBlockElement> = ({ title, subtitle, text, extraText, elements, customMargin, topMargin, bottomMargin }) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 360;
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 360;
        }
    };
    const marginStyles = customMargin
    ? {
        marginTop: topMargin ? `${topMargin}px` : undefined,
        marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
    }
    : {};
    return (
        <div className="mx-auto w-full max-w-screen-xl mt-40" style={marginStyles}>
            <div className="flex flex-col items-center md:w-9/12 mx-auto">
                <h2 className={cn(styles.PhotoGalleryBlock__title)}>{title}</h2>
                <h3 className={cn(styles.PhotoGalleryBlock__subtitle)}>{subtitle}</h3>
                <div className={cn(styles.PhotoGalleryBlock__text, "mb-4")}>{text}</div>
                <div className={cn(styles.PhotoGalleryBlock__text)}>{extraText}</div>
            </div>
            <div className={"hidden lg:flex gap-5 justify-center mb-12 mt-12"}>
                <button onClick={scrollLeft} className={styles.PhotoGalleryBlock__arrowButton}><img src={Arrow} alt="left button" /></button>
                <button onClick={scrollRight} className={cn("transform rotate-180", styles.PhotoGalleryBlock__arrowButton)}>
                    <img src={Arrow} alt="right button" />
                </button>
            </div>
            <div ref={scrollContainerRef} className={cn("hidden md:flex justify-start max-w-screen-xl gap-8" +
                " w-full my-0 mx-auto py-6 px-2 md:px-8", styles.scrollContainer)}>
                {elements &&
                    elements.map(({ videoLink, image }, index) => (
                        <div
                            className={cn(styles.PhotoGalleryBlock__element, "overflow-x-auto")}
                            key={index}
                        >
                            {videoLink && (
                                <iframe
                                    src={videoLink}
                                    allow="autoplay; encrypted-media; screen-wake-lock"
                                    allowFullScreen
                                    style={{ width: "100%", height: "100%" }}
                                ></iframe>
                            )}
                            {image && (
                                <img src={`${ApiBaseUrl}/api/media/${image}`} width={"100%"} height={"100%"} alt="" />
                            )}
                        </div>
                    ))}
            </div>
            <div className={cn("flex my-8 mx-auto md:hidden")}>
                <Slider backgroundColor="white">
                    {elements &&
                        elements.map(({ videoLink, image }, index) => (
                            <div
                                className={cn("overflow-x-auto h-44 w-full")}
                                key={index}
                            >
                                {videoLink && (
                                    <iframe
                                        src={videoLink}
                                        allow="autoplay; encrypted-media; screen-wake-lock"
                                        allowFullScreen
                                        style={{ width: "100%", height: "100%" }}
                                    ></iframe>
                                )}
                                {image && (
                                    <img src={`${ApiBaseUrl}/api/media/${image}`} width={"100%"} height={"100%"} alt="" />
                                )}
                            </div>
                        ))}
                </Slider>
            </div>
        </div>
    )
}


export const PhotoGalleryBlockInfo: TypedBlockTypeInfo<PhotoGalleryBlockElement> = {
    id: "PhotoGalleryBlock",
    name: "PhotoGalleryBlock",
    preview: preview,
    renderer: PhotoGalleryBlock,
    initialData: {
        title: "",
        subtitle: "",
        text: "",
        extraText: "",
        elements: [
            {
                videoLink: "",
                image: null,
            },
        ],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "videoLink",
                        type: "String",
                        name: "Video Link",
                    },
                    {
                        id: "image",
                        name: "Image",
                        type: "Custom",
                        customType: "Image",
                    },
                ]
            }
        },
        fields: [
            {
                id: "title",
                name: "Title",
                type: "String",
            },
            {
                id: "subtitle",
                name: "Subtext",
                type: "String",
            },
            {
                id: "text",
                name: "Text",
                type: "String",
            },
            {
                id: "extraText",
                name: "Extra Text",
                type: "String",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};
