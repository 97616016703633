import { action, observable } from "mobx";
import { AdminTraitListItemDto } from "src/interfaces/TraitPageDto";
import { RequestTracking } from "src/utils/Loadable";
import { AdminApi } from "src/clients/adminApiClient";
import { RootStore } from "src/stores/RootStore";
import {AdminRouteNames} from "../../../../pages/Admin/AdminRoutes";

export class TraitPageStore extends RequestTracking {
  @observable items: AdminTraitListItemDto[] = [];
  @observable totalPages: number = 0;
  @observable currentPage: number = 0;
  @observable traitTypeId = "0";

  constructor(public rootStore: RootStore) {
    super();
  }

  @action async load(id: string) {
    this.traitTypeId = id;
    this.items = await this.track(() => AdminApi.getTrait(id));
    this.totalPages = AdminApi.getTotalPages(this.items.length);
  }

  @action async delete() {
    console.log(this.traitTypeId)
    await this.track(() => AdminApi.deleteTraitType(Number(this.traitTypeId)));
    await this.rootStore.routerStore.goTo(AdminRouteNames.traitList);
  }
}
