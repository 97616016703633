//OurWorkBlock
import React, { useState } from "react";
import style from "./OurWorkBlock.module.css"
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import { ApiBaseUrl } from "../../api/apiClientBase";
import cn from "classnames";

export interface OurWorkBlockElement {
    title: string;
    ourWork: {
        serviceTitle: string;
        serviceText: string;
        backgroundImg: number | null;
        textColor: string;
    }[];
    buttonText: string;
    buttonLink: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const OurWorkBlock: React.FC<OurWorkBlockElement> = (props: OurWorkBlockElement) => {
    const textColor = (color: string) => {
        return color === "black" ? "#373737" : "#FFFFFF";
    };
    if (!props.ourWork || props.ourWork.length === 0) {
        return <div>No data available</div>;
    }
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn("my-12")} style={marginStyles}>
            <div className={cn("text-center mb-8", style.ourWorkBlock__title)}>{props.title}</div>
            <div className={cn("flex flex-col max-w-screen-xl w-full my-0 mx-auto gap-5 px-2 md:px-8")}>
                <div
                    className={cn(style.ourWorkBlock__firstItem, "flex flex-col p-7 relative overflow-hidden", style.ourWorkBlock__card)}
                    style={{color: textColor(props.ourWork[0].textColor)}}
                >
                    <div className={cn(style.ourWorkBlock__cardImageWrapper, "absolute inset-0")}>
                        <div
                            className={cn(style.ourWorkBlock__cardImage)}
                            style={{
                                backgroundImage: `url(${ApiBaseUrl}/api/media/${props.ourWork[0].backgroundImg})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                width: '100%',
                                height: '100%',
                            }}
                        />
                    </div>
                    <div className="flex flex-col flex-grow relative z-10">
                        <div className={style.ourWorkBlock__imageTitle}>{props.ourWork[0].serviceTitle}</div>
                        <div className={cn(style.ourWorkBlock__imageText, "w-6/12")}>{props.ourWork[0].serviceText}</div>
                    </div>
                </div>
                <div className={cn("grid grid-cols-1 lg:grid-cols-2 gap-5", style.ourWorkBlock__grid)}>
                    {props.ourWork.slice(1, props.ourWork.length).map((service, index) => (
                        <div
                            key={index}
                            className={cn(style.ourWorkBlock__miniItem, "flex flex-col p-7 relative overflow-hidden", style.ourWorkBlock__card)}
                            style={{color: textColor(service.textColor)}}
                        >
                            <div className={cn(style.ourWorkBlock__cardImageWrapper, "absolute inset-0")}>
                                <div
                                    className={cn(style.ourWorkBlock__cardImage)}
                                    style={{
                                        backgroundImage: `url(${ApiBaseUrl}/api/media/${service.backgroundImg})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                />
                            </div>
                            <div className="flex flex-col flex-grow relative z-10">
                                <div className={style.ourWorkBlock__imageTitle}>{service.serviceTitle}</div>
                                <div className={cn(style.ourWorkBlock__imageText, "flex w-6/12")}>{service.serviceText}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex justify-center mt-8">
                <a type="button" className={style.ourWorkBlock__button} href={props.buttonLink}>
                    <span>{props.buttonText}</span>
                </a>
            </div>
        </div>
    )
}

export const OurWorkBlockInfo: TypedBlockTypeInfo<OurWorkBlockElement> = {
    id: "OurWorkBlock",
    name: "OurWorkBlock",
    preview: preview,
    renderer: OurWorkBlock,
    initialData: {
        title: "string",
        ourWork: [
            {
                serviceTitle: "string",
                serviceText: "string",
                backgroundImg: null,
                textColor: "black"
            },
        ],
        buttonText: "string",
        buttonLink: "#",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            ourWork: {
                fields: [
                    {
                        id: "serviceTitle",
                        name: "Service Title",
                        type: "String",
                    },
                    {
                        id: "serviceText",
                        name: "Service Text",
                        type: "String",
                    },
                    {
                        id: "backgroundImg",
                        name: "Background Image",
                        type: "Custom",
                        customType: "Image",
                    },
                    {
                        id: "textColor",
                        name: "Text Color",
                        type: "Radio",
                        possibleValues: [
                            {
                                id: "black",
                                name: "black"
                            },
                            {
                                id: "white",
                                name: "white"
                            }
                        ]
                    },
                ],
            },
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "ourWork",
                name: "OurWork",
                type: "List",
                listType: "ourWork",
            },
            {
                id: "buttonText",
                type: "String",
                name: "Button Text",
            },
            {
                id: "buttonLink",
                type: "String",
                name: "Button Link",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};
