interface CurvedTextProps {
  text: string;
  color: string;
}

export const CurvedText3: React.FC<CurvedTextProps> = ({ text, color }) => {
  const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
  const id = `curved${Math.random() * 100}`;

  return (
    <svg width="646" height="319" viewBox="0 0 715 367" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id={id}
        d="M22 99L51.4399 79.7033C71.3712 66.639 93.1186 56.5828 115.982 49.8584L123.628 47.6095C140.787 42.5625 158.582 40 176.468 40H183.937C214.674 40 244.774 48.765 270.705 65.2669V65.2669C288.075 76.3205 303.173 90.5912 315.187 107.311L316.284 108.838C335.362 135.391 361.639 155.931 392.013 168.035L395.364 169.37C406.428 173.78 417.896 177.102 429.604 179.292L444.268 182.034C463.71 185.669 482.753 191.186 501.127 198.506L519.924 205.994C529.939 209.984 539.444 215.152 548.237 221.388L559.006 229.026C588.17 249.709 605.5 283.246 605.5 319V319"
        stroke="white"
        stroke-width="80"
      />
      <text fontSize="38px" fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={color}>
        <textPath href={`#${id}`} textAnchor="start">
          <tspan x="0" dy="0.2em" style={{ lineHeight: "38px" }}>
            {repeatedText}
          </tspan>
        </textPath>
      </text>
    </svg>
  );
};
