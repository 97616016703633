import React, { FC, useState } from "react";
import { useObserver } from "mobx-react";
import { AdminInputBox } from "src/components/common/AdminInputBox";
import { GlobalLink, GlobalSocialLink } from "../common/commonGlobal";
import { GlobalSettingsPageStore } from "../../../../stores/pages/adminStores/globalSettings/globalSettingsPageStore";

export const FooterSettings: FC<{ s: GlobalSettingsPageStore }> = ({ s }) => {
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);

  const handleDragStart = (index: number) => {
    setDraggedItemIndex(index);
  };

  const handleDrop = (index: number, list: any[]) => {
    if (draggedItemIndex === null) return;

    const updatedList = [...list];
    const [movedItem] = updatedList.splice(draggedItemIndex, 1);
    updatedList.splice(index, 0, movedItem);

    list.length = 0;
    updatedList.forEach((item) => list.push(item));

    setDraggedItemIndex(null);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  return useObserver(() => (
    <div>
      <GlobalSocialLink value={s.footerSocialLink} name={`Social Link`} />
      <div className="text-gray-700 text-xl py-4">
        We have: call, email, facebook, instagram, telegram, linkedin, location, skype, twitter, vk, whatsapp, youtube
      </div>
      <div className={`flex justify-between pb-4 mb-4 border-b-2`}>
        <span className="text-gray-700 text-xl pr-4 flex-col">Contact list</span>
        <div className={`flex flex-col w-3/4`}>
          {s.footerContactLinkList.map((el, index) => (
            <div
              key={index}
              className={`flex flex-row items-center`}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(index, s.footerContactLinkList)}
            >
              <div
                className={`w-8 h-8 flex items-center justify-center bg-blue-500 text-white cursor-move mr-4 rounded`}
                draggable="true"
                onDragStart={() => handleDragStart(index)}
              >
                ⇅
              </div>
              <div className="flex-grow border-grey border-solid border-l-2 pl-4 mb-4">
                <AdminInputBox
                  value={el.icon}
                  label={"Icon"}
                  onChange={(e) => (el.icon = e.target.value)}
                />
                <AdminInputBox
                  value={el.link}
                  label={"Url"}
                  onChange={(e) => (el.link = e.target.value)}
                />
                <AdminInputBox
                  value={el.text}
                  label={"Text"}
                  onChange={(e) => (el.text = e.target.value)}
                />
                <button
                  onClick={() => s.footerContactLinkList.splice(index, 1)}
                  className={`text-white w-full font-bold py-2 px-4 rounded inline-block bg-red-600 hover:bg-red-900`}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
          <button
            onClick={() =>
              s.footerContactLinkList.push({ icon: "", link: "", text: "" })
            }
            className={`text-white font-bold py-2 px-4 rounded inline-block bg-blue-500 hover:bg-blue-100 hover:text-black`}
          >
            Add
          </button>
        </div>
      </div>

      <div className={`flex justify-between pb-4 mb-4 border-b-2`}>
        <span className="text-gray-700 text-xl pr-4">Link list</span>
        <div className={`flex flex-col w-3/4`}>
          {s.footerLinkList.map((el, index) => (
            <div
              key={index}
              className={`flex flex-row items-center`}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(index, s.footerLinkList)}
            >
              <div
                className={`w-8 h-8 flex items-center justify-center bg-blue-500 text-white cursor-move mr-4 rounded`}
                draggable="true"
                onDragStart={() => handleDragStart(index)}
              >
                ⇅
              </div>
              <div className="flex-grow border-grey border-solid border-l-2 pl-4 mb-4">
                <AdminInputBox
                  value={el.title}
                  label={"Title"}
                  onChange={(e) => (el.title = e.target.value)}
                />
                <AdminInputBox
                  value={el.url}
                  label={"Url"}
                  onChange={(e) => (el.url = e.target.value)}
                />
                <GlobalLink value={el.list} name={`Link List`} />
                <button
                  onClick={() => s.footerLinkList.splice(index, 1)}
                  className={`text-white w-full font-bold py-2 px-4 rounded inline-block bg-red-600 hover:bg-red-900 mb-4`}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
          <button
            onClick={() =>
              s.footerLinkList.push({ title: "", url: "", list: [] })
            }
            className={`text-white font-bold py-2 px-4 rounded inline-block bg-blue-500 hover:bg-blue-100 hover:text-black`}
          >
            Add
          </button>
        </div>
      </div>

      <GlobalLink value={s.footerTopLink} name={`Bottom Link`} />
    </div>
  ));
};
