// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".readMoreBlock_readMoreBlock__title__hJd4v{\n  font-weight: bold;\n  font-size: 18px;\n  line-height: 26px;\n  color: #373737;\n  margin-bottom: 16px;\n}\n\n@media (max-width: 767px) {\n  .readMoreBlock_readMoreBlock__title__hJd4v{\n    font-size: 15px;\n    margin-bottom: 6px;\n  }\n}\n\n.readMoreBlock_readMoreBlock__button__6irxb{\n  font-size: 12px;\n  line-height: 18px;\n  margin-top: 11px;\n  color: #567DD0;\n  border-bottom: 1px dashed rgba(75, 116, 205, 0.6);\n}\n", "",{"version":3,"sources":["webpack://../components/src/blocks/ReadMoreBlock/readMoreBlock.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE;IACE,eAAe;IACf,kBAAkB;EACpB;AACF;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,iDAAiD;AACnD","sourcesContent":[".readMoreBlock__title{\n  font-weight: bold;\n  font-size: 18px;\n  line-height: 26px;\n  color: #373737;\n  margin-bottom: 16px;\n}\n\n@media (max-width: 767px) {\n  .readMoreBlock__title{\n    font-size: 15px;\n    margin-bottom: 6px;\n  }\n}\n\n.readMoreBlock__button{\n  font-size: 12px;\n  line-height: 18px;\n  margin-top: 11px;\n  color: #567DD0;\n  border-bottom: 1px dashed rgba(75, 116, 205, 0.6);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"readMoreBlock__title": "readMoreBlock_readMoreBlock__title__hJd4v",
	"readMoreBlock__button": "readMoreBlock_readMoreBlock__button__6irxb"
};
export default ___CSS_LOADER_EXPORT___;
