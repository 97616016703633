interface CurvedTextProps {
  text: string;
  width: string;
  fontSize: string;
  transform?: string
}

export const CurvedText: React.FC<CurvedTextProps> = ({ text, width, fontSize, transform }) => {
  const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
  return (
    <svg width="1300" height="1128" viewBox="0 0 1226 1131" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="curvePracticalLearning"
        d="M339.456 58C206.955 58 -126.045 308.5 190.457 503.5C506.959 698.5 1032 201 1141 625.5C1250 1050 1003.89 733 808 733C708 733 459 699 541.457 1119"
        stroke="#FA5354"
        stroke-width={`${width}`}
        transform={`${transform}`}
      />
      <text fontSize={`${fontSize}`} fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={"#FFFFFF"} transform={`${transform}`}>
        <textPath href="#curvePracticalLearning" textAnchor="start" >
          <tspan x="0" dy="0.2em" style={{ lineHeight: "54.6px" }} >
            {repeatedText}
          </tspan>
        </textPath>
      </text>
    </svg>
  );
};
