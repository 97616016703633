import React from "react";
import style from "./LineAndImageBlock.module.css";

interface CurveTextProps {
    text: string;
    color: string;
}

export const CurveText2: React.FC<CurveTextProps> = ({ text, color }) => {
    const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
    const id = `curved${Math.random() * 100}`;

    return (
        <div className={style.svgContainer}>
            <svg width="100%" height="auto"  viewBox="0 0 715 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    id={id}
                    d="M245.3 891.851C245.3 788.849 212.8 612.849 158.3 485.849C114.824 384.537 -19.1996 221.349 118.8 98.3494C256.8 -24.6504 426.3 164.85 442.8 250.35"                    
                    stroke="white"
                    strokeWidth={"115"}
                    transform="translate(350, 0)"
                />
                <text fontSize={"2.625rem"} fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={color}>
                    <textPath href={`#${id}`} textAnchor="start">
                        {repeatedText}
                    </textPath>
                </text>
            </svg>
        </div>
    )
};


