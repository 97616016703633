import React, { useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./HistoryBlock.module.css";
import cn from "classnames";
import TimelineSlider from "./TimelineSlider";

export interface HistoryBlockElement {
    title: string;
    subtitle: string;
    redtext: string;
    description: string;
    elements: { title: string; subtitle?: string; text: string }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

const normalizeText = (text: string): string => {
    return text.toLowerCase().replace(/\s+/g, ' ').trim();
};

const highlightMatch = (subtitle: string, redtext: string): JSX.Element[] => {
    if (!redtext) return [<span key="0">{subtitle}</span>];
    const normalizedSubtitle = normalizeText(subtitle);
    const normalizedRedtext = normalizeText(redtext);
    const regex = new RegExp(`(${normalizedRedtext.split(' ').join('\\s*')})`, "gi");
    const parts = normalizedSubtitle.split(regex);

    let originalIndex = 0;

    return parts.map((part, index) => {
        const originalPart = subtitle.substring(originalIndex, originalIndex + part.length);
        originalIndex += part.length;

        return regex.test(part) ? (
            <span key={index} style={{ backgroundColor: "rgba(243, 215, 227, 1)", color: "rgba(250, 83, 84, 1)" }}>
                {originalPart}
            </span>
        ) : (
            <span key={index}>{originalPart}</span>
        );
    });
}

export const HistoryBlock = (props: HistoryBlockElement) => {
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={style.historyContainer} style={marginStyles}>
            <div className={style.header}>
                <h2>{props.title}</h2>
                <h3>{highlightMatch(props.subtitle, props.redtext)}</h3>
                <p>{props.description}</p>
            </div>
            <div className={style.timelineContainer}>
                <TimelineSlider elements={props.elements} />
            </div>
        </div>
    )
}

export const HistoryBlockInfo: TypedBlockTypeInfo<HistoryBlockElement> = {
    id: "historyBlock",
    name: "historyBlock",
    preview: preview,
    renderer: HistoryBlock,
    initialData: {
        title: "История Quartier Latin",
        subtitle: "",
        redtext: "",
        description: "",
        elements: [
            {
                title: "string",
                subtitle: "",
                text: "string",
            },
        ],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "title",
                        type: "String",
                        name: "Title"
                    },
                    {
                        id: "subtitle",
                        type: "String",
                        name: "Subtitle",
                    },
                    {
                        id: "text",
                        type: "String",
                        name: "Text"
                    },
                ],
            },
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "subtitle",
                type: "String",
                name: "Subtitle",
            },
            {
                id: "redtext",
                type: "String",
                name: "Redtext",
            },
            {
                id: "description",
                type: "String",
                name: "Description",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ]
    }
}