import React, {useContext, useEffect, useRef, useState} from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./LevelTestBlock.module.css";
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import { OverlayDialog } from "../../ui/OverlayDialog/OverlayDialog";
import cn from "classnames";
import { ComponentHostContext } from "../index";

export interface LevelTestBlockElement {
    title: string;
    text: string;
    backgroundImg: number | null;
    textButton: string;
    link?: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const LevelTestBlock = (props: LevelTestBlockElement) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const blockRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.5,
            }
        );

        if (blockRef.current) {
            observer.observe(blockRef.current);
        }

        return () => {
            if (blockRef.current) {
                observer.unobserve(blockRef.current);
            }
        };
    }, []);
    const handleNoLinkClick = () => {
        setIsDialogVisible(true);
    };
    const cl = useContext(ComponentHostContext);
    console.log(cl)

    const marginTop = props.customMargin ?  props.topMargin || "0" : "0" ;
    const marginBottom = props.customMargin ? props.bottomMargin || "0" : "0";

    return (
        <div className={styles.levelTestBlock} ref={blockRef} style={{ marginTop: `${marginTop}px`, marginBottom: `${marginBottom}px` }}>
            <div className={"flex mx-auto max-w-screen-xl relative w-full h-full self-center"}>
                <div className={`flex flex-col lg:justify-center mx-auto lg:mx-0 lg:h-full gap-5 ${styles.levelTestBlock__info}`}>
                    <div className={styles.levelTestBlock__title}>{props.title}</div>
                    <div className={styles.levelTestBlock__text}>{props.text}</div>
                    {props.link ? (
                        <a type={"button"} className={styles.button} href={props.link}>
                            <span>{props.textButton}</span>
                        </a>
                    ) : (
                        <button type={"button"} className={styles.button} onClick={handleNoLinkClick}>
                            <span>{props.textButton}</span>
                        </button>
                    )}
                </div>
                <img
                    src={`${ApiBaseUrl}/api/media/${props.backgroundImg}`}
                    alt=""
                    className={`${styles.levelTestBlock__image} ${isVisible ? styles.levelTestBlock__imageVisible : ""}`}
                />
            </div>
            {isDialogVisible && (
                <OverlayDialog cancel={() => setIsDialogVisible(false)} backgroundColor="rgba(243, 215, 227, 1)">
                    <p className="text-center py-10">{cl?.testModalSetting?.testModalDescription}</p>
                    <form className={cn(styles.form, "relative w-full")}>
                        <input type="text" className={cn(`${styles.input}`)} placeholder={cl?.testModalSetting?.testModalPlaceholder} required={true} />
                        <button onClick={() => setIsDialogVisible(false)} className={`flex justify-center ${styles.buttonOverlay}`}>{cl?.testModalSetting?.testModalButton}</button>
                    </form>
                </OverlayDialog>
            )}
        </div>
    );
};

export const LevelTestBlockInfo: TypedBlockTypeInfo<LevelTestBlockElement> = {
    id: "LevelTestBlock",
    name: "LevelTestBlock",
    preview: preview,
    renderer: LevelTestBlock,
    initialData: {
        title: "string",
        text: "string",
        backgroundImg: null,
        textButton: "string",
        link: "",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "backgroundImg",
                type: "Custom",
                customType: "Image",
                name: "Background Image",
            },
            {
                id: "textButton",
                type: "String",
                name: "Text Button",
            },
            {
                id: "link",
                type: "String",
                name: "Link",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};
