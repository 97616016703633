interface CurvedTextProps {
  text: string;
  width: string;
  fontSize: string;
}

export const CurvedText: React.FC<CurvedTextProps> = ({ text, width, fontSize }) => {
  const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
  return (
    <svg width="1300" height="1128" viewBox="0 0 1226 1131" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="curveCoursePracticalLearning"
        d="M105.377 39C-24.1227 180 133.879 335.5 314.379 368.5C494.879 401.5 722.379 253 844.379 412.5C966.379 572 697.379 747 1070.38 1005.5"
        stroke="#FA5354"
        stroke-width={`${width}`}
      />
      <text fontSize={`${fontSize}`} fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={"#FFFFFF"} >
        <textPath href="#curveCoursePracticalLearning" textAnchor="start" >
          <tspan x="0" dy="0.2em" style={{ lineHeight: "54.6px" }} >
            {repeatedText}
          </tspan>
        </textPath>
      </text>
    </svg>
  );
};
