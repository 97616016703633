"use client";

import { YMapMarkerProps, YMapProps, YMap as YMapType, LngLat } from "@yandex/ymaps3-types";
import React, { PropsWithChildren, ReactNode, forwardRef } from "react";
import { useMapComponents } from "../../utils/MapComponentsProvider";
import cn from "classnames";
import styles from "./Map.module.css";

export interface MapProps extends YMapProps, PropsWithChildren<any> {
  isGeolocationEnabled?: boolean;
  onGeolocatePosition?: (posititon: LngLat) => void;
  children?: ReactNode;
  onResize?: (asdf: any) => void;
}

export type MapRef = YMapType;

export * from "../../utils/MapComponentsProvider";

export const Map = forwardRef<MapRef, MapProps>(
  ({ onResize, children, className, isGeolocationEnabled = true, onGeolocatePosition, ...props }, ref) => {
    const components = useMapComponents();

    if (!components) {
      return null;
    }

    const {
      YMap,
      YMapControls,
      YMapDefaultFeaturesLayer,
      YMapDefaultSchemeLayer,
      YMapGeolocationControl,
      YMapZoomControl,
      YMapListener,
    } = components;

    return (
      <div className={cn("relative overflow-hidden", styles.map, className)}>
        <YMap {...props} ref={ref}>
          <YMapDefaultSchemeLayer />
          <YMapDefaultFeaturesLayer />

          <YMapControls orientation={"vertical"} position={"right"}>
            {isGeolocationEnabled ? <YMapGeolocationControl onGeolocatePosition={onGeolocatePosition} /> : null}
            <YMapZoomControl />
          </YMapControls>

          {children}
          <YMapListener />
        </YMap>
      </div>
    );
  }
);

Map.displayName = "Map";

export interface MapPointProps extends YMapMarkerProps {
  info?: ReactNode;
  shortInfo?: ReactNode;
  isActive?: boolean;
  zIndex?: number;
}

export function MapPoint({ zIndex = 1, shortInfo, info, isActive = false, ...props }: MapPointProps) {
  const components = useMapComponents();

  if (!components) {
    return null;
  }

  const { YMapMarker } = components;

  return (
    <YMapMarker zIndex={isActive ? 999 : 1} {...props}>
      <div>
        <div className={cn(styles.marker, `${info && !isActive ? "cursor-pointer" : ""}`)} />
        {shortInfo && !isActive ? <div className={styles.shortInfo}>{shortInfo}</div> : null}
        {info && isActive ? <div className={styles.shortInfo}>{info}</div> : null}
      </div>
    </YMapMarker>
  );
}
export default Map;
