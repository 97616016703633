import React, { useContext, useEffect, useRef, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./SocialNetworkBlock.module.css"
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import { ComponentHostContext } from "../index";
import cn from "classnames";
import style from "../MoreAboutCompanyBlock/MoreAboutCompanyBlock.module.css";
import { Slider } from "../../ui/Slider/Slider";

export interface SocialNetworkBlockElement {
    title: string;
    elements: {
        buttonTitle: string,
        buttonText: string,
        link: string,
        buttonIcon: number | null
    }[];
    image_1: number | null;
    image_2: number | null;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const SocialNetworkBlock = (props: SocialNetworkBlockElement) => {
    const cl = useContext(ComponentHostContext);
    const [isVisible, setIsVisible] = useState(false);
    const blockRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.5,
            }
        );

        if (blockRef.current) {
            observer.observe(blockRef.current);
        }

        return () => {
            if (blockRef.current) {
                observer.unobserve(blockRef.current);
            }
        };
    }, []);
    const marginTop = props.customMargin ? props.topMargin || "0" : "0" ;
    const marginBottom = props.customMargin ? props.bottomMargin || "0" : "0";
    return (
        <div className={`${styles.socialNetworkBlock} overflow-hidden pb-12`} style={{ backgroundColor: '#00309D', marginTop: `${marginTop}px`, marginBottom: `${marginBottom}px` }}  ref={blockRef}>
            <div className="flex justify-center w-full">
                <div className="flex flex-col lg:flex-row justify-center md:justify-between w-full max-w-screen-xl">
                    <div className={`flex flex-col h-full ${styles.socialNetworkBlock__text}`} >
                        <div className={styles.socialNetworkBlock__title}>{props.title}</div>
                        <div className="hidden md:flex md:flex-row md:justify-center lg:flex-col md:h-full lg:mb-10" style={{ gap: '40px' }}>
                            {props.elements.map((el) => (
                                <div className={`flex flex-col h-full justify-between`} key={el.buttonTitle}>
                                    <div className={"flex flex-col " + styles.socialNetworkBlock__buttonBlock}>
                                        <span className={styles.socialNetworkBlock__buttonTitle}>{el.buttonTitle}</span>
                                        {el.link ? (
                                            <a type={"button"} className={styles.button} href={el.link}>
                                                {el.buttonIcon && <img src={`${ApiBaseUrl}/api/media/${el.buttonIcon}`} alt="" />}
                                                <span>{el.buttonText}</span>
                                            </a>
                                        ) : (
                                            <button type={"button"} className={styles.button} onClick={() => cl?.showContactUsForm()}>
                                                {el.buttonIcon && <img src={`${ApiBaseUrl}/api/media/${el.buttonIcon}`} alt="" />}
                                                <span>{el.buttonText}</span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={cn("flex text-center md:hidden")}>
                            <Slider>
                                {props.elements.map((el, index) => (
                                    <div key={index} className='flex flex-col items-center gap-5 w-full'>
                                        <div className={"w-6/12"}>
                                            {el.buttonTitle}
                                        </div>
                                        <div className={style.socialNetworkBlock__buttonBlock}>
                                            {el.link ? (
                                                <a type={"button"} className={styles.button} href={el.link}>
                                                    {el.buttonIcon && <img src={`${ApiBaseUrl}/api/media/${el.buttonIcon}`} alt="" />}
                                                    <span>{el.buttonText}</span>
                                                </a>
                                            ) : (
                                                <button type={"button"} className={styles.button} onClick={() => cl?.showContactUsForm()}>
                                                    {el.buttonIcon && <img src={`${ApiBaseUrl}/api/media/${el.buttonIcon}`} alt="" />}
                                                    <span>{el.buttonText}</span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                    <div className={"flex " + styles.socialNetworkBlock__imageBlock}>
                        <img
                            src={`${ApiBaseUrl}/api/media/${props.image_1}`}
                            className={`${styles.firstImage} ${isVisible ? styles.firstImageVisible : ""}`}
                            alt=''
                        />
                        <img
                            src={`${ApiBaseUrl}/api/media/${props.image_2}`}
                            className={`${styles.secondImage} ${isVisible ? styles.secondImageVisible : ""}`}
                            alt=''
                        />
                    </div>
                </div>
            </div>
        </div>

    );
};

export const SocialNetworkBlockInfo: TypedBlockTypeInfo<SocialNetworkBlockElement> = {
    id: "SocialNetworkBlock",
    name: "SocialNetworkBlock",
    preview: preview,
    renderer: SocialNetworkBlock,
    initialData: {
        title: "string",
        elements: [
            {
                buttonTitle: "string",
                buttonText: "string",
                link: "",
                buttonIcon: null
            },
        ],
        image_1: null,
        image_2: null,
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "buttonTitle",
                        type: "String",
                        name: "Button Title"
                    },
                    {
                        id: "buttonText",
                        type: "String",
                        name: "Text Button"
                    },
                    {
                        id: "link",
                        type: "String",
                        name: "Link"
                    },
                    {
                        id: "buttonIcon",
                        type: "Custom",
                        customType: "Image",
                        name: "Button icon",
                    },
                ],
            },
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
            {
                id: "image_1",
                type: "Custom",
                customType: "Image",
                name: "First Image",
            },
            {
                id: "image_2",
                type: "Custom",
                customType: "Image",
                name: "Second Image",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};
