import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./RequirementsListBlock.module.css";
import cn from "classnames";

export interface RequirementsListBlockElement {
    title: string;
    elements: {
        title: string,
        description: string
    }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const RequirementsListBlock = (props: RequirementsListBlockElement) => {
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className="flex flex-col py-12 px-4 md:px-12 lg:px-20 max-w-screen-xl justify-center gap-10 mx-auto my-0" style={marginStyles}>
            <div className={"flex flex-col gap-7 items-center text-center"}>
                <div className={cn(styles.requirementsListBlock__title, "mb-8")}>
                    {props.title}
                </div>
                <div className={cn("flex flex-col w-full mb-5")}>
                    <hr className={cn("mb-5 w-full")}/>
                    {props.elements && props.elements.map((element) =>
                        <div className={cn("flex flex-col")}>
                            <div className={cn("flex flex-col gap-5")}>
                                <p className={cn(styles.requirementsListBlock__text)}>{element.title}</p>
                                {element.description && <p className={cn(styles.requirementsListBlock__description)}>{element.description}</p>}
                            </div>
                            <hr className={cn("my-5 w-full")}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export const RequirementsListBlockInfo: TypedBlockTypeInfo<RequirementsListBlockElement> = {
    id: "RequirementsListBlock",
    name: "RequirementsListBlock",
    preview: preview,
    renderer: RequirementsListBlock,
    initialData: {
        title: "string",
        elements: [{
            title: "string",
            description: "string",
        }],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "title",
                        type: "String",
                        name: "Title",
                    },
                    {
                        id: "description",
                        type: "String",
                        name: "Description",
                    },
                ]
            }
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ]
    }
}
