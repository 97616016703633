// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconAndImageBlock_text__wUnSK {\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 21.6px;\n  text-align: center;\n}\n\n.IconAndImageBlock_icon__3oGDk{\n  width: 40px;\n  height: 40px;\n}\n\n@media (max-width: 1024px) {\n  .IconAndImageBlock_text__wUnSK {\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 16.8px;\n    text-align: center;\n  }\n\n  .IconAndImageBlock_icon__3oGDk{\n    width: 24px;\n    height: 24px;\n  }\n}\n", "",{"version":3,"sources":["webpack://../components/src/blocks/IconAndImageBlock/IconAndImageBlock.module.css"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACtB;;AAEA;EACI,WAAW;EACX,YAAY;AAChB;;AAEA;EACI;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;EACtB;;EAEA;IACI,WAAW;IACX,YAAY;EAChB;AACJ","sourcesContent":[".text {\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 21.6px;\n    text-align: center;\n}\n\n.icon{\n    width: 40px;\n    height: 40px;\n}\n\n@media (max-width: 1024px) {\n    .text {\n        font-size: 14px;\n        font-weight: 400;\n        line-height: 16.8px;\n        text-align: center;\n    }\n\n    .icon{\n        width: 24px;\n        height: 24px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "IconAndImageBlock_text__wUnSK",
	"icon": "IconAndImageBlock_icon__3oGDk"
};
export default ___CSS_LOADER_EXPORT___;
