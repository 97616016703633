import React from "react";
import style from "./LineAndImageBlock.module.css";

interface CurveTextProps {
    text: string;
    color: string;
}

export const CurveText10: React.FC<CurveTextProps> = ({ text, color }) => {
    const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
    const id = `curved${Math.random() * 100}`;

    return (
        <div className={style.svgContainer}>
            <svg width="100%" height="auto" viewBox="0 0 760 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    id={id}
                    d="M58 19C68.5 111 80 189.5 241 343C374 469.804 284.5 654.5 514 714.5C690 742 759 566.877 640.5 445C447.5 246.5 367.5 146.5 439.5 30.5"                    
                    stroke="white"
                    stroke-width="115"
                    transform="translate(150, -100)"
                />
                <text fontSize="2.625rem" fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={color}>
                    <textPath href={`#${id}`} textAnchor="start">
                        {repeatedText}
                    </textPath>
                </text>
            </svg>
        </div>
    );
};