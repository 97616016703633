interface CurvedTextProps {
  text: string;
  color: string;
}

export const CurvedText1: React.FC<CurvedTextProps> = ({ text, color }) => {
  const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
  const id = `curved${Math.random() * 100}`;

  return (
    <svg width="715" height="367" viewBox="0 0 715 367" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id={id}
        d="M36 126.5L71.11 99.1092C94.3941 80.9443 121.528 68.3505 150.431 62.2929L156.936 60.9295C178.743 56.3592 201.259 56.3457 223.072 60.89V60.89C256.188 67.7891 286.306 84.9021 309.184 109.819L324.5 126.5L346.006 145.238C365.263 162.016 386.5 176.376 409.244 187.997V187.997C431.996 199.622 456.085 208.419 480.974 214.192L505.319 219.839C528.665 225.255 551.169 233.812 572.214 245.278L591.947 256.03C605.876 263.619 618.379 273.573 628.896 285.448V285.448C638.897 296.738 646.96 309.605 652.761 323.527L661.5 344.5"
        stroke="white"
        stroke-width="115"
      />
      <text fontSize="42px" fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={color}>
        <textPath href={`#${id}`} textAnchor="start">
          {repeatedText}
        </textPath>
      </text>
    </svg>
  );
};
