import React, { useRef } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./ImgAndSignatureBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";
import Arrow from "../../assets/icons/arrow.svg"
import preview from "./preview.png";
import { Slider } from "../../ui/Slider/Slider";

export interface ImgAndSignatureBlockElement {
    imageList: {
        image: number | null;
        signature: string;
    }[];
    videoLink?: string;
    videoWidth?: string;
    videoHeight?: string;
    signature: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const ImgAndSignatureBlock = (props: ImgAndSignatureBlockElement) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 360;
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 360;
        }
    };
    const marginStyles = props.customMargin
    ? {
        marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
        marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
    }
    : {};
    return (
        <div className={`max-w-screen-xl w-full my-12 gap-5 mx-auto px-2 md:px-8`} style={marginStyles}>
            {props.videoLink && (
                <iframe
                    src={props.videoLink}
                    title="YouTube video player"
                    width={props.videoWidth || "100%"}
                    height={props.videoHeight || "300px"}
                    className={cn(styles.video)}
                />
            )
            }
            {props.imageList.length >= 3 &&
                <div className={"hidden lg:flex gap-5 justify-center mb-6 mt-6 mb-0"}>
                    <button onClick={scrollLeft}><img src={Arrow} alt="left button" /></button>
                    <button onClick={scrollRight} className="transform rotate-180">
                        <img src={Arrow} alt="right button" />
                    </button>
                </div>
            }
            {props.imageList && !props.videoLink &&
                <div ref={scrollContainerRef} className="hidden md:flex flex-row gap-8 my-8">
                    {props.imageList && props.imageList.map((el, idx) => (
                        <div key={idx}>
                            {!props.videoLink && el.image && <img src={`${ApiBaseUrl}/api/media/${el.image}`} alt="" className={cn(styles.image)}/>}
                            {el.signature && <div style={{ color: "rgba(110, 110, 110, 1)" }} className="my-2 text-sm">{el.signature}</div>}
                        </div>
                    ))}
                </div>
            }
            {props.imageList &&
                <div className="flex md:hidden">
                    <Slider backgroundColor="white">
                        {props.imageList && props.imageList.map((el, idx) => (
                            <div key={idx}>
                                {!props.videoLink && el.image && <img src={`${ApiBaseUrl}/api/media/${el.image}`} alt="" className={cn(styles.image)}/>}
                                {el.signature && <div style={{ color: "rgba(110, 110, 110, 1)" }} className="my-2 text-sm">{el.signature}</div>}
                            </div>
                        ))}
                    </Slider>
                </div>}
            {props.signature && <div style={{ color: "rgba(110, 110, 110, 1)" }} className="text-sm my-2">{props.signature}</div>}
        </div>
    );
};

export const ImgAndSignatureBlockInfo: TypedBlockTypeInfo<ImgAndSignatureBlockElement> = {
    id: "imgAndSignatureBlock",
    name: "ImgAndSignatureBlock",
    preview: preview,
    renderer: ImgAndSignatureBlock,
    initialData: {
        imageList: [
            {
                image: null,
                signature: "",
            }
        ],
        videoLink: "",
        videoWidth: "100%",
        videoHeight: "300px",
        signature: "",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            imageList: {
                fields: [
                    {
                        id: "image",
                        name: "Image",
                        type: "Custom",
                        customType: "Image",
                    },
                    {
                        id: "signature",
                        name: "Signature",
                        type: "String",
                    }
                ]
            }
        },
        fields: [
            {
                id: "imageList",
                name: "Image List",
                type: "List",
                listType: "imageList",
            },
            {
                id: "videoLink",
                name: "Video Link:",
                type: "String",
            },
            {
                id: "videoWidth",
                name: "Video Width:",
                type: "String",
            },
            {
                id: "videoHeight",
                name: "Video Height:",
                type: "String",
            },
            {
                id: "signature",
                name: "Signature",
                type: "String"
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};
