import React, { useContext, useState } from "react";
import styles from "./ServiceBlock.module.css"
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import { ApiBaseUrl } from "../../api/apiClientBase";
import cn from "classnames";
import { ComponentHostContext } from "../index";

interface ServiceOption {
  serviceIcon: number | null;
  serviceTitle: string;
  serviceText: string;
  backgroundImg: number | null;
  servicePrice: string;
  serviceButtonText: string;
  textAndButtonColor: string;
  link: string;
}

interface ServiceProps {
  title: string;
  text: string;
  services: ServiceOption[];
  buttonText: string;
  buttonLink: string;
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
}

export const ServiceBlock: React.FC<ServiceProps> = ({ title, text, services, buttonText, buttonLink, topMargin, bottomMargin, customMargin }) => {
  const textColor = (color: string) => {
    return color === "black" ? ["#373737", "#FFFFFF"] : ["#FFFFFF", "#373737"];
  };
  const cl = useContext(ComponentHostContext);
  const handleServiceClick = (service: ServiceOption) => {
    cl?.setSelectedService({ id: service.serviceIcon || 0, name: service.serviceTitle });
    cl?.showContactUsFormServiceBlue("rgba(0, 48, 157, 1)");
  };
  const marginStyles = customMargin
    ? {
      marginTop: topMargin ? `${topMargin}px` : undefined,
      marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
    }
    : {};
  return (
    <div id={title.replace(/\s+/g, '-').toLowerCase()} className={cn("mb-28 md:mb-32 lg:mb-20", styles.serviceBlock)} style={marginStyles}>
      <div className={styles.serviceBlock__title + " text-center md:mb-6 mb-0"}>
        {title}
      </div>
      <div className={cn("text-center md:mb-6 mb-0 px-2", styles.serviceBlock__text)}>
        {text}
      </div>
      <div className={"flex flex-col justify-between max-w-screen-xl w-full gap-5 my-0 mx-auto py-6 px-2 md:px-8"}>
        <a href={services[0].link || "#"} className={cn(styles.serviceBlock__firstItem, styles.serviceBlock__card, "flex flex-col justify-between p-7 relative overflow-hidden")}>
          <div className={cn(styles.serviceBlock__cardImageWrapper, "absolute inset-0")}>
            <div
              className={cn(styles.serviceBlock__cardImage)}
              style={{
                backgroundImage: `url(${ApiBaseUrl}/api/media/${services[0]?.backgroundImg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                height: '100%',
              }}
            />
          </div>
          <div style={{ color: textColor(services[0]?.textAndButtonColor)[0] }} className="flex flex-col justify-between flex-grow relative z-10">
            <div>
              <div className={styles.serviceBlock__imageTitle}>
                {services[0]?.serviceTitle}
              </div>
              <div className={styles.serviceBlock__imageText}>
                {services[0]?.serviceText}
              </div>
            </div>
            <div>
              <div className={styles.serviceBlock__imagePrice}>
                {services[0]?.servicePrice}
              </div>
              <button className={cn(styles.serviceBlock__imageButtonFirst, services[0]?.textAndButtonColor === "black" ? styles.blackButton : styles.whiteButton)}
                onClick={(e) => {
                  e.preventDefault();
                  services[0] && handleServiceClick(services[0])
                }}>
                {services[0]?.serviceButtonText}
              </button>
            </div>
          </div>
        </a>
        <div className={cn(styles.serviceBlock__secondRow, "flex w-full justify-between gap-5 md:gap-0 flex-col lg:flex-row")}>
          <a
            href={services[1]?.link || "#"}
            className={cn("flex-1 flex flex-col justify-between p-7 relative overflow-hidden", styles.serviceBlock__secItem, styles.serviceBlock__card)}
            style={{
              color: textColor(services[1]?.textAndButtonColor)[0]
            }}
          >
            <div className={cn(styles.serviceBlock__cardImageWrapper, "absolute inset-0")}>
              <div
                className={cn(styles.serviceBlock__cardImage, "hidden md:flex")}
                style={{
                  backgroundImage: `url(${ApiBaseUrl}/api/media/${services[1]?.backgroundImg})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
            <div className="flex flex-col justify-between flex-grow relative z-10">
              <div>
                <img src={`${ApiBaseUrl}/api/media/${services[1]?.serviceIcon}`} className={cn(styles.serviceIcon, "flex md:hidden")} alt="" />
                <div className={styles.serviceBlock__imageTitle}>
                  {services[1]?.serviceTitle}
                </div>
                <div className={styles.serviceBlock__imageText}>
                  {services[1]?.serviceText}
                </div>
              </div>
              <div>
                <div className={styles.serviceBlock__imagePrice}>
                  {services[1]?.servicePrice}
                </div>
                <button
                  className={cn(styles.serviceBlock__imageButton, services[1]?.textAndButtonColor === "black" ? styles.blackButton : styles.whiteButton)}
                  onClick={(e) => {
                    e.preventDefault();
                    services[1] && handleServiceClick(services[1])
                  }}
                >
                  {services[1]?.serviceButtonText}
                </button>
              </div>
            </div>
          </a>
          <a
            href={services[2]?.link || "#"}
            className={cn("flex-1 flex flex-col justify-between p-7 relative overflow-hidden md:hidden", styles.serviceBlock__secItem, styles.serviceBlock__card)}
            style={{
              color: textColor(services[2]?.textAndButtonColor)[0]
            }}
          >
            <div className={cn(styles.serviceBlock__cardImageWrapper, "absolute inset-0")}>
              <div
                className={cn(styles.serviceBlock__cardImage, "hidden md:flex")}
                style={{
                  backgroundImage: `url(${ApiBaseUrl}/api/media/${services[1]?.backgroundImg})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
            <div className="flex flex-col justify-between flex-grow relative z-10">
              <div>
                <img src={`${ApiBaseUrl}/api/media/${services[2]?.serviceIcon}`} className={cn(styles.serviceIcon, "md:hidden lg:flex")} alt="" />
                <div className={styles.serviceBlock__imageTitle}>
                  {services[2]?.serviceTitle}
                </div>
                <div className={styles.serviceBlock__imageText}>
                  {services[2]?.serviceText}
                </div>
              </div>
              <div>
                <div className={styles.serviceBlock__imagePrice}>
                  {services[2]?.servicePrice}
                </div>
                <button
                  className={cn(styles.serviceBlock__imageButton, services[2]?.textAndButtonColor === "black" ? styles.blackButton : styles.whiteButton)}
                  onClick={(e) => {
                    e.preventDefault();
                    services[2] && handleServiceClick(services[2])
                  }}
                >
                  {services[2]?.serviceButtonText}
                </button>
              </div>
            </div>
          </a>
          <a href={services[2]?.link || "#"} className={cn("flex-1 p-7 flex-col justify-between hidden lg:flex lg:ml-5", styles.serviceBlock__secondRowItems, styles.serviceBlock__card)}
            style={{ color: textColor(services[2]?.textAndButtonColor)[0] }}>
            <div className={cn(styles.serviceBlock__cardImageWrapper, "absolute inset-0")}>
              <div
                className={cn(styles.serviceBlock__cardImage, "hidden lg:flex")}
                style={{
                  backgroundImage: `url(${ApiBaseUrl}/api/media/${services[2]?.backgroundImg})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
            <div className="flex flex-col justify-between flex-grow relative z-10">
              <div style={{ color: textColor(services[2]?.textAndButtonColor)[0] }}>
                <div className="text-content">
                  <img src={`${ApiBaseUrl}/api/media/${services[2]?.serviceIcon}`} className={cn(styles.serviceIcon, "lg:hidden")} alt="" />
                  <div className={styles.serviceBlock__imageTitle}>
                    {services[2]?.serviceTitle}
                  </div>
                  <div className={styles.serviceBlock__imageText + " flex"}>
                    {services[2]?.serviceText}
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.serviceBlock__imagePrice}>
                  {services[2]?.servicePrice}
                </div>
                <button className={cn(styles.serviceBlock__imageButton, services[2]?.textAndButtonColor === "black" ? styles.blackButton : styles.whiteButton)}
                  onClick={(e) => {
                    e.preventDefault();
                    services[2] && handleServiceClick(services[2])
                  }}>
                  {services[2]?.serviceButtonText}
                </button>
              </div>
            </div>
          </a>
          <a href={services[3]?.link || "#"} className={cn("flex-1 p-7 flex-col justify-between flex md:hidden", styles.serviceBlock__secondRowItems, styles.serviceBlock__card)}
            style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${services[3]?.backgroundImg})`, color: textColor(services[3]?.textAndButtonColor)[0] }}>
            <div className="text-content">
              <img src={`${ApiBaseUrl}/api/media/${services[3]?.serviceIcon}`} className={styles.serviceIcon} alt="" />
              <div className={styles.serviceBlock__imageTitle}>
                {services[3]?.serviceTitle}
              </div>
              <div className={styles.serviceBlock__imageText + " flex"}>
                {services[3]?.serviceText}
              </div>
            </div>
            <div>
              <div className={styles.serviceBlock__imagePrice}>
                {services[3]?.servicePrice}
              </div>
              <button className={cn(styles.serviceBlock__imageButton, services[3]?.textAndButtonColor === "black" ? styles.blackButton : styles.whiteButton)}
                onClick={(e) => {
                  e.preventDefault();
                  services[3] && handleServiceClick(services[3])
                }}>
                {services[3]?.serviceButtonText}
              </button>
            </div>
          </a>
          <div>
            <div className="grid grid-cols-1 gap-5 md:hidden">
              {services.slice(4)?.map((service, index) => (
                <a href={service.link || "#"} key={index} className={cn(styles.serviceBlock__miniItem, styles.serviceBlock__card, "flex flex-col justify-between p-7")}>
                  <div className="text-content">
                    <img src={`${ApiBaseUrl}/api/media/${service.serviceIcon}`} className={styles.serviceIcon} alt="" />
                    <div className={styles.serviceBlock__imageTitle}>
                      {service.serviceTitle}
                    </div>
                    <div className={cn(styles.serviceBlock__imageText, styles.styleText, "flex")}>
                      {service.serviceText}
                    </div>
                  </div>
                  <div>
                    <div className={styles.serviceBlock__imagePrice}>
                      {service.servicePrice}
                    </div>
                    <button
                      className={cn(styles.serviceBlock__imageButton, styles.blackButton)}
                      style={{
                        zIndex: 30,
                        position: 'relative',
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleServiceClick(service)
                      }}
                    >
                      {service.serviceButtonText}
                    </button>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
        {/*<div className={styles.serviceBlock__rightItem + ""} style={{backgroundImage: url(${ApiBaseUrl}/api/media/${services[3].backgroundImg})}}>*/}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {services.slice(3, services.length)?.map((service, index) => (
            <a href={service.link || "#"} key={index} className={cn(styles.serviceBlock__miniItem, styles.serviceBlock__card, " flex-col justify-between p-7 hidden lg:flex")}>
              <div className="text-content">
                <img src={`${ApiBaseUrl}/api/media/${service.serviceIcon}`} className={styles.serviceIcon} alt="" />
                <div className={styles.serviceBlock__imageTitle} style={{
                  width: service.serviceTitle?.length < 30 ? '60%' : '90%px'
                }}>
                  {service.serviceTitle}
                </div>
                <div className={cn(styles.serviceBlock__imageText, styles.styleText, "flex")}>
                  {service.serviceText}
                </div>
              </div>
              <div>
                <div className={styles.serviceBlock__imagePrice}>
                  {service.servicePrice}
                </div>
                <button
                  className={cn(styles.serviceBlock__imageButton, styles.blackButton)}
                  style={{
                    zIndex: 30,
                    position: 'relative',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleServiceClick(service)
                  }}
                >
                  {service.serviceButtonText}
                </button>
              </div>
            </a>
          ))}
        </div>
        <div>
          <div className={cn(styles.serviceBlock__container, "hidden md:grid lg:hidden gap-7 grid-cols-1 md:grid-cols-2")}>
            {services.slice(2, services.length)?.map((service, index) =>
              <a href={service.link || "#"} key={index} className={cn(styles.serviceBlock__miniItem, styles.serviceBlock__card, "flex flex-col justify-between p-7")}>
                <div className="text-content">
                  <img
                    src={`${ApiBaseUrl}/api/media/${service.serviceIcon}`}
                    className={styles.serviceIcon}
                    alt=""
                  />
                  <div className={styles.serviceBlock__imageTitle}>
                    {service.serviceTitle}
                  </div>
                  <div className={cn(styles.serviceBlock__imageText, styles.styleText, "flex")}>
                    {service.serviceText}
                  </div>
                </div>
                <div>
                  <div className={styles.serviceBlock__imagePrice}>
                    {service.servicePrice}
                  </div>
                  <button className={cn(styles.serviceBlock__imageButton, styles.miniButton, styles.blackButton)}
                    style={{ zIndex: 30, position: 'relative', }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleServiceClick(service)
                    }}>
                    {service.serviceButtonText}
                  </button>
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center md:mt-6 mt-0">
        <a type="button" className={styles.serviceBlock__button} href={buttonLink}>
          <span>{buttonText}</span>
        </a>
      </div>
    </div>
  )
};

export const ServiceBlockInfo: TypedBlockTypeInfo<ServiceProps> = {
  id: "ServiceBlock",
  name: "ServiceBlock",
  preview: preview,
  renderer: ServiceBlock,
  initialData: {
    title: "string",
    text: "string",
    services: [
      {
        serviceIcon: null,
        serviceTitle: "string",
        serviceText: "string",
        backgroundImg: null,
        servicePrice: "string",
        serviceButtonText: "string",
        textAndButtonColor: "black",
        link: "#",
      },
    ],
    buttonText: "string",
    buttonLink: "#",
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
  },
  definition: {
    subTypes: {
      service: {
        fields: [
          {
            id: "serviceIcon",
            name: "Service Icon",
            type: "Custom",
            customType: "Image",
          },
          {
            id: "serviceTitle",
            name: "Service Title",
            type: "String",
          },
          {
            id: "serviceText",
            name: "Service Text",
            type: "String",
          },
          {
            id: "backgroundImg",
            name: "Background Image",
            type: "Custom",
            customType: "Image",
          },
          {
            id: "servicePrice",
            name: "Service Price",
            type: "String",
          },
          {
            id: "serviceButtonText",
            name: "Service Button Text",
            type: "String",
          },
          {
            id: "textAndButtonColor",
            name: "Text And Button Color",
            type: "Radio",
            possibleValues: [
              {
                id: "black",
                name: "black"
              },
              {
                id: "white",
                name: "white"
              }
            ]
          },
          {
            id: "link",
            name: "Service Link",
            type: "String",
          },
        ],
      },
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "text",
        type: "String",
        name: "Text",
      },
      {
        id: "services",
        name: "Services",
        type: "List",
        listType: "service",
      },
      {
        id: "buttonText",
        type: "String",
        name: "Button Text",
      },
      {
        id: "buttonLink",
        type: "String",
        name: "Button Link"
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ],
  },
};
