"use client";

import { ReactifiedModule, Reactify } from "@yandex/ymaps3-types/reactify";
import { BaseModule } from "@yandex/ymaps3-types/reactify/reactify";
import React, { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";

export const MapComponentsContext = createContext<
  | ReactifiedModule<
      BaseModule &
        typeof import("@yandex/ymaps3-types/index") &
        typeof import("@yandex/ymaps3-types/packages/controls/index")
    >
  | undefined
>(undefined as any);

export function MapComponentsProvider({ apiKey, children }: PropsWithChildren<{ apiKey: string }>) {
  const [data, setData] = useState<
    | {
        controls: typeof import("@yandex/ymaps3-types/packages/controls/index");
        reactify: Reactify;
        ymaps: typeof import("@yandex/ymaps3-types/index");
      }
    | undefined
  >();

  const c = useMemo(() => {
    if (data?.reactify) {
      return {
        ...data.reactify.module(data.ymaps),
        ...data.reactify.module(data.controls),
      };
    }
  }, [data]);

  useEffect(() => {
    if (data) return;

    const script = document.createElement("script");
    script.id = "Ymaps";
    script.src = `https://api-maps.yandex.ru/v3/?apikey=${apiKey}&lang=ru_RU`;
    script.onload = async () => {
      // @ts-ignore
      const ymaps: typeof import("@yandex/ymaps3-types/index") = window.ymaps3;

      await ymaps.ready;
      const ymaps3Reactify = await ymaps.import("@yandex/ymaps3-reactify");
      const controls = await ymaps.import("@yandex/ymaps3-controls@0.0.1");
      const reactify = ymaps3Reactify.reactify.bindTo(React, ReactDOM);

      setData({ controls, reactify, ymaps });
    };
    script.onerror = () => {
      console.error('Ошибка загрузки скрипта Яндекс.Карт');
    };

    document.body.appendChild(script);
  }, [apiKey]);

  return <MapComponentsContext.Provider value={c}>{children}</MapComponentsContext.Provider>;
}

export function useMapComponents() {
  return useContext(MapComponentsContext);
}
