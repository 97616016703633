import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./FeaturesBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";
import Slider from "../../ui/Slider/Slider";

export interface FeaturesBlockElement {
  title: string;
  elements: {
    icon: null | number,
    text: string
  }[];
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
}

export const FeaturesBlock = (props: FeaturesBlockElement) => {
  const marginStyles = props.customMargin
    ? {
      marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
      marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
    }
    : {};
  return (
    <div className="flex flex-col gap-0 md:gap-12 items-center my-10 max-w-screen-xl mx-auto md:py-12 overflow-x-hidden" style={marginStyles}>
      <div className={cn("flex flex-col text-center gap-7")}>
        <div className={cn(styles.featuresBlock__title, "")}>
          {props.title}
        </div>
      </div>
      <div className={cn("flex flex-col gap-3 md:gap-10 items-center px-4", styles.featuresBlock__itemsContainer)}>
        <div className={cn("hidden md:grid gap-5 w-full justify-center", styles.featuresBlock__grid)}>
          {props.elements && props.elements.map((element, index) => (
            <div className={cn("flex flex-col", styles.featuresBlock__element)} key={index}>
              <img src={`${ApiBaseUrl}/api/media/${element.icon}`} alt="" className={cn(styles.featuresBlock__icon)} />
              <p className={cn(styles.featuresBlock__elText)}>{element.text}</p>
            </div>
          ))}
        </div>
        <div className={cn("flex md:hidden")}>
          <Slider backgroundColor="white">
            {props.elements && props.elements.map((element, index) => (
              <div className={cn("flex flex-col", styles.featuresBlock__element)} key={index}>
                <img src={`${ApiBaseUrl}/api/media/${element.icon}`} alt="" className={cn(styles.featuresBlock__icon)} />
                <p className={cn(styles.featuresBlock__elText)}>{element.text}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export const FeaturesBlockInfo: TypedBlockTypeInfo<FeaturesBlockElement> = {
  id: "FeaturesBlock",
  name: "FeaturesBlock",
  preview: preview,
  renderer: FeaturesBlock,
  initialData: {
    title: "string",
    elements: [{
      icon: null,
      text: "string"
    }],
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
  },
  definition: {
    subTypes: {
      element: {
        fields: [
          {
            id: "icon",
            type: "Custom",
            customType: "Image",
            name: "Icon",
          },
          {
            id: "text",
            type: "String",
            name: "Text"
          }
        ]
      }
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "elements",
        type: "List",
        listType: "element",
        name: "Elements",
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ]
  }
}
