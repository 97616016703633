import React from "react";

import styles from "./LocationBlock.module.css";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import cn from "classnames";
import { Map, MapComponentsProvider, MapPoint } from "../../ui/Map";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface LocationProps {
  title: string;
  subtitle: string;
  texts: { item: string }[];
  align: string;
  centerLongitude: number;
  centerLatitude: number;
  zoom: number;
  duration: number;
  apiKey: string;
  points: {
    longitude: number;
    latitude: number;
  }[];
  bottomCards: boolean;
  cards?: {
    icon: null | number;
    title: string;
    text: string;
  }[];
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
}

export const LocationBlock: React.FC<LocationProps> = ({
  apiKey,
  title,
  subtitle,
  texts,
  align,
  centerLongitude,
  centerLatitude,
  zoom,
  duration,
  points,
  bottomCards,
  cards,
  customMargin,
  topMargin,
  bottomMargin
}) => {
  const marginStyles = customMargin
    ? {
      marginTop: topMargin ? `${topMargin}px` : undefined,
      marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
    }
    : {};
  return (
    <div className="my-12" style={marginStyles}>
      <div className={cn(`flex flex-col justify-center align-center lg:px-12 lg:pt-12 max-w-screen-xl w-full mx-auto items-center lg:${align}`)}>
        <h3 className={styles.location}>{title}</h3>
        {texts && texts.map(({ item }) => <p className={styles.text}>{item}</p>)}
        <h2 className={styles.title}>{subtitle}</h2>
      </div>
      <div className={cn(styles.map, "flex flex-col max-w-screen-xl w-full my-0 mx-auto py-6")}>
        <MapComponentsProvider apiKey={apiKey}>
          <Map
            className={"h-full"}
            isGeolocationEnabled={false}
            location={{
              zoom,
              center: [centerLongitude, centerLatitude],
              duration,
            }}
          >
            {points &&
              points.map(({ latitude, longitude }, key) => <MapPoint key={key} coordinates={[longitude, latitude]} />)}
          </Map>
        </MapComponentsProvider>
      </div>
      {bottomCards &&
        <div className={styles.bottomCards}>
          {cards && cards.map((card) => (
            <div className={styles.bottomCard}>
              <img className={styles.bottomCardImg} src={`${ApiBaseUrl}/api/media/${card.icon}`} alt="" />
              <p className={styles.bottomCardTitle}>{card.title}</p>
              <p className={styles.bottomCardText}>{card.text}</p>
            </div>
          ))}
        </div>
      }
    </div>
  )
};

export const LocationBlockInfo: TypedBlockTypeInfo<LocationProps> = {
  id: "locationBlock",
  name: "LocationBlock",
  preview: preview,
  renderer: LocationBlock,
  initialData: {
    apiKey: "",
    title: "",
    subtitle: "1 South Ave, Garden City, NY 11530",
    texts: [
      {
        item: "string",
      },
    ],
    align: "string",
    centerLatitude: 55.751603,
    centerLongitude: 37.6177,
    zoom: 10,
    duration: 300,
    points: [
      {
        latitude: 55.751603,
        longitude: 37.6177,
      },
    ],
    bottomCards: false,
    cards: [{
      icon: null,
      title: "",
      text: ""
    }],
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
  },
  definition: {
    subTypes: {
      text: {
        fields: [
          {
            id: "item",
            name: "Item",
            type: "String",
          },
        ],
      },
      point: {
        fields: [
          {
            id: "longitude",
            name: "Longitude",
            type: "Number",
          },
          {
            id: "latitude",
            name: "Latitude",
            type: "Number",
          },
        ],
      },
      card: {
        fields: [
          {
            id: "icon",
            name: "Icon",
            type: "Custom",
            customType: "Image"
          },
          {
            id: "title",
            name: "Title",
            type: "String",
          },
          {
            id: "text",
            name: "Text",
            type: "String",
          },
        ]
      }
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "subtitle",
        type: "String",
        name: "Subtitle",
      },
      {
        id: "texts",
        name: "Texts",
        type: "List",
        listType: "text",
      },
      {
        id: "align",
        name: "Align",
        type: "Radio",
        possibleValues: [
          {
            id: "items-start",
            name: "left",
          },
          {
            id: "items-center",
            name: "center",
          },
          {
            id: "items-end",
            name: "right",
          },
        ],
      },
      {
        id: "apiKey",
        type: "String",
        name: "Api Key",
      },
      {
        id: "centerLongitude",
        type: "Number",
        name: "CenterLongitude",
      },
      {
        id: "centerLatitude",
        type: "Number",
        name: "Center Latitude",
      },
      {
        id: "zoom",
        type: "String",
        name: "Zoom",
      },
      {
        id: "duration",
        type: "String",
        name: "Duration",
      },
      {
        id: "points",
        name: "Points",
        type: "List",
        listType: "point",
      },
      {
        id: "bottomCards",
        type: "CheckBox",
        name: "Bottom Cards",
      },
      {
        id: "cards",
        name: "Cards",
        type: "List",
        listType: "card",
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ],
  },
};
