import React, { FC, useState } from "react";
import { useObserver } from "mobx-react";
import { AdminInputBox } from "src/components/common/AdminInputBox";
import { LinkDto, SocialLinkDto } from "src/interfaces/GlobalSettingsDto";
import { RouterLink } from "mobx-state-router";
import { AdminRouteNames } from "src/pages/Admin/AdminRoutes";

export const GlobalSocialLink: FC<{ value: SocialLinkDto[]; name: string }> = ({ value, name }) => {
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);

  const handleDragStart = (index: number) => {
    setDraggedItemIndex(index);
  };

  const handleDrop = (index: number) => {
    if (draggedItemIndex === null) return;

    const updatedList = [...value];
    const [movedItem] = updatedList.splice(draggedItemIndex, 1);
    updatedList.splice(index, 0, movedItem);

    value.length = 0;
    updatedList.forEach((item) => value.push(item));

    setDraggedItemIndex(null);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  return useObserver(() => (
    <div className={`flex justify-between pb-4 mb-4 border-b-2`}>
      <span className="text-gray-700 text-xl pr-4 flex flex-col">
        {name}
        <span>We have: telegram, vk, facebook, instagram, linkedIn, twitter, youtube</span>
      </span>
      <div className={`flex flex-col w-3/4`}>
        {value &&
          value.map((el, index) => (
            <div
              key={index}
              className={`flex flex-row items-center`}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(index)}
            >
              <div
                className={`w-8 h-8 flex items-center justify-center bg-blue-500 text-white cursor-move mr-4 rounded`}
                draggable="true"
                onDragStart={() => handleDragStart(index)}
              >
                ⇅
              </div>
              <div className="flex-grow border-grey border-solid border-l-2 pl-4 mb-4">
                <AdminInputBox
                  value={el.icon}
                  label={"social name"}
                  onChange={(e) => (el.icon = e.target.value)}
                />
                <AdminInputBox
                  value={el.link}
                  label={"link"}
                  onChange={(e) => (el.link = e.target.value)}
                />
                <button
                  onClick={() => value.splice(index, 1)}
                  className={`text-white w-full font-bold py-2 px-4 rounded inline-block bg-red-600 hover:bg-red-900 mb-4`}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        <button
          onClick={() => value.push({ icon: "", link: "" })}
          className={`text-white font-bold py-2 px-4 rounded inline-block bg-blue-500 hover:bg-blue-100 hover:text-black`}
        >
          Add
        </button>
      </div>
    </div>
  ));
};

export const GlobalLink: FC<{ value: LinkDto[]; name: string }> = ({ value, name }) => {
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);

  const handleDragStart = (index: number) => {
    setDraggedItemIndex(index);
  };

  const handleDrop = (index: number) => {
    if (draggedItemIndex === null) return;

    const updatedList = [...value];
    const [movedItem] = updatedList.splice(draggedItemIndex, 1);
    updatedList.splice(index, 0, movedItem);

    value.length = 0;
    updatedList.forEach((item) => value.push(item));

    setDraggedItemIndex(null);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  return useObserver(() => (
    <div className={`flex justify-between pb-4 mb-4 border-b-2`}>
      <span className="text-gray-700 text-xl pr-4">{name}</span>
      <div className={`flex flex-col w-3/4`}>
        {value &&
          value.map((el, index) => (
            <div
              key={index}
              className={`flex flex-row items-center`}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(index)}
            >
              <div
                className={`w-8 h-8 flex items-center justify-center bg-blue-500 text-white cursor-move mr-4 rounded`}
                draggable="true"
                onDragStart={() => handleDragStart(index)}
              >
                ⇅
              </div>
              <div className="flex-grow border-grey border-solid border-l-2 pl-4 mb-4">
                <AdminInputBox
                  value={el.name}
                  label={"name"}
                  onChange={(e) => (el.name = e.target.value)}
                />
                <AdminInputBox
                  value={el.link}
                  label={"link"}
                  onChange={(e) => (el.link = e.target.value)}
                />
                <button
                  onClick={() => value.splice(index, 1)}
                  className={`text-white w-full font-bold py-2 px-4 rounded inline-block bg-red-600 hover:bg-red-900 mb-4`}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        <button
          onClick={() => value.push({ name: "", link: "" })}
          className={`text-white font-bold py-2 px-4 rounded inline-block bg-blue-500 hover:bg-blue-100 hover:text-black`}
        >
          Add
        </button>
      </div>
    </div>
  ));
};

export const ChooseLang = () => {
  return (
    <div className={`flex justify-between mb-4 w-full`}>
      <RouterLink routeName={AdminRouteNames.globalSettingsEditor} params={{ lang: "en" }}>
        <a
          className={`text-white font-bold py-2 px-4 rounded inline-block bg-blue-500 hover:bg-blue-100 hover:text-black`}
        >
          English
        </a>
      </RouterLink>

      <RouterLink routeName={AdminRouteNames.globalSettingsEditor} params={{ lang: "ru" }}>
        <a
          className={`text-white font-bold py-2 px-4 rounded inline-block bg-blue-500 hover:bg-blue-100 hover:text-black`}
        >
          Russian
        </a>
      </RouterLink>

      <RouterLink routeName={AdminRouteNames.globalSettingsEditor} params={{ lang: "fr" }}>
        <a
          className={`text-white font-bold py-2 px-4 rounded inline-block bg-blue-500 hover:bg-blue-100 hover:text-black`}
        >
          France
        </a>
      </RouterLink>

      <RouterLink routeName={AdminRouteNames.globalSettingsEditor} params={{ lang: "esp" }}>
        <a
          className={`text-white font-bold py-2 px-4 rounded inline-block bg-blue-500 hover:bg-blue-100 hover:text-black`}
        >
          Spaien
        </a>
      </RouterLink>

      <RouterLink routeName={AdminRouteNames.globalSettingsEditor} params={{ lang: "cn" }}>
        <a
          className={`text-white font-bold py-2 px-4 rounded inline-block bg-blue-500 hover:bg-blue-100 hover:text-black`}
        >
          China
        </a>
      </RouterLink>
    </div>
  );
};
