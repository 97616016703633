import React, { FC } from "react";
import { useObserver } from "mobx-react";
import { AdminInputBox } from "src/components/common/AdminInputBox";
import { GlobalSettingsPageStore } from "../../../../stores/pages/adminStores/globalSettings/globalSettingsPageStore";

export const TestModalSettings: FC<{ s: GlobalSettingsPageStore }> = ({ s }) => {
  return useObserver(() => (
    <div>
      <AdminInputBox
        value={s.testModalDescription}
        label="Description"
        onChange={(e) => (s.testModalDescription = e.target.value)}
      />
      <AdminInputBox
        value={s.testModalPlaceholder}
        label="Placeholder"
        onChange={(e) => (s.testModalPlaceholder = e.target.value)}
      />
       <AdminInputBox
        value={s.testModalButton}
        label="Button"
        onChange={(e) => (s.testModalButton = e.target.value)}
      />
    </div>
  ));
};
