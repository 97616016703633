import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./AboutCompanyBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface AboutCompanyBlockElement {
    title: string;
    subtitle: string;
    text: string;
    image: null | number;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const AboutCompanyBlock = (props: AboutCompanyBlockElement) => {
    const marginStyles = props.customMargin
    ? {
      marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
      marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
    }
    : {};
    return (
        <div className="flex flex-col md:gap-10 items-center my-10" style={marginStyles}>
            <div className={cn(styles.aboutCompanyBlock__info, "max-w-screen-xl flex flex-col lg:px-12")}>
                <div className={cn(styles.aboutCompanyBlock__title, "mb-6 md:mb-10")}>
                    {props.title}
                </div>
                <div className={cn(styles.aboutCompanyBlock__text, "mb-3")}>
                    {props.subtitle}
                </div>
                <div className={cn(styles.aboutCompanyBlock__text, "mb-10")}>
                    {props.text}
                </div>
            </div>
            <img src={`${ApiBaseUrl}/api/media/${props.image}`} alt="" className={cn(styles.aboutCompanyBlock__img)} />
        </div>
    )
}

export const AboutCompanyBlockInfo: TypedBlockTypeInfo<AboutCompanyBlockElement> = {
    id: "AboutCompanyBlock",
    name: "AboutCompanyBlock",
    preview: preview,
    renderer: AboutCompanyBlock,
    initialData: {
        title: "string",
        subtitle: "string",
        text: "string",
        image: null,
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "subtitle",
                type: "String",
                name: "Subtitle",
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "image",
                type: "Custom",
                customType: "Image",
                name: "Image",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
              },
              {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
              },
              {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
              },
        ]
    }
}
