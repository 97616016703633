import React, {useRef} from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./NewPartnersBlock.module.css"
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import Arrow from "../../assets/icons/white_arrow.svg"
import Document from "../../assets/icons/document.svg"
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";

export interface NewPartnersBlockElement {
    title: string,
    elements: {
        logo: number | null,
        name: string,
        description: string,
        price: string,
        boldTextPrice: string
    }[];
    bottomText: string,
    buttons: {
        buttonText: string
        buttonLink: string
    }[],
    showButtons: boolean;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const NewPartnersBlock = (props: NewPartnersBlockElement) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 260;
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 260;
        }
    };

    const highlightMatch = (subtitle: string, boldtext: string): JSX.Element[] => {
        if (!boldtext) return [<span key="0">{subtitle}</span>];

        const normalizeText = (text: string): string => {
            return text.toLowerCase().replace(/\s+/g, ' ').trim();
        };

        const normalizedSubtitle = normalizeText(subtitle);
        const normalizedBoldtext = normalizeText(boldtext);

        const escapedBoldtext = normalizedBoldtext.replace(/[$()*+?.\\^|{}]/g, '\\$&');

        const regex = new RegExp(
            `(${escapedBoldtext.split(' ').join('\\s*')})`,
            "gi"
        );

        const parts = normalizedSubtitle.split(regex);
        let originalIndex = 0;

        return parts.map((part, index) => {
            const originalPart = subtitle.substring(originalIndex, originalIndex + part.length);
            originalIndex += part.length;

            return regex.test(part) ? (
                <span key={index} style={{ fontWeight: "bold" }}>
                {originalPart}
            </span>
            ) : (
                <span key={index}>{originalPart}</span>
            );
        });
    };

    const marginTop = props.customMargin ? props.topMargin || "0" : "48";
    const marginBottom = props.customMargin ? props.bottomMargin || "0" : "48" ;

    return (
        <div style={{ marginTop: `${marginTop}px`, marginBottom: `${marginBottom}px` }}>
            <div className={styles.newPartnersBlock__title + " text-center mt-10 mb-28 md:mb-32 lg:mb-40"}>{props.title}</div>
                <div className={"hidden lg:flex gap-5 justify-center mb-6 mt-6 mb-0"}>
                    <button onClick={scrollLeft} className={styles.newPartnersBlock__arrowButton}><img src={Arrow} alt="left button" /></button>
                    <button onClick={scrollRight} className={cn("transform rotate-180", styles.newPartnersBlock__arrowButton)}>
                        <img src={Arrow} alt="right button" />
                    </button>
                </div>
            <div ref={scrollContainerRef} className={cn("hidden md:flex justify-start max-w-screen-xl gap-8 " +
                "w-full my-0 mx-auto py-6 px-2 md:px-8 overflow-x-auto", styles.scrollContainer)}>
                {props.elements && props.elements.map((element, index) =>
                    <div className={styles.newPartnersBlock__card} key={index}>
                        <img src={`${ApiBaseUrl}/api/media/${element?.logo}`} className={styles.newPartnersBlock__cardImage} alt="logo"/>
                        <div className={"flex flex-col gap-3"}>
                            <div className={styles.newPartnersBlock__name}
                                 style={element.name?.length >= 40 ? {width: "100%"} : {width: "50%"}}>{element?.name}</div>
                            <div className={styles.newPartnersBlock__description}>{element?.description}</div>
                            <div className={styles.newPartnersBlock__description}>{highlightMatch(element?.price, element?.boldTextPrice)}</div>
                        </div>
                    </div>
                )}
            </div>
            <div className={cn("flex my-8 mx-auto md:hidden")}>
                <Slider backgroundColor="white">
                    {props.elements?.map((element, index) => (
                        <div className={styles.newPartnersBlock__card + " mx-auto my-0"} key={index}>
                            <img src={`${ApiBaseUrl}/api/media/${element.logo}`} className={styles.newPartnersBlock__cardImage} alt="logo"/>
                            <div className={"flex flex-col gap-3"}>
                                <div className={styles.newPartnersBlock__name}>{element?.name}</div>
                                <div className={styles.newPartnersBlock__description}>{element?.description}</div>
                                <div className={styles.newPartnersBlock__description}>{highlightMatch(element?.price, element.boldTextPrice)}</div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            {props.showButtons &&
                <div className="flex lg:flex-row flex-col justify-between mx-auto content-center max-w-screen-xl md:mt-20 mt-2 px-4 gap-5">
                    <div className={styles.newPartnersBlock__bottomText}>{props.bottomText}</div>
                    <div className={"flex md:flex-row flex-col gap-5 justify-center"}>
                        {props.buttons && props.buttons.map((button, index) =>
                            <a type={"button"} className={styles.newPartnersBlock__button} href={button.buttonLink} key={index}>
                                <img src={Document} alt="document"/>
                                {button.buttonText}
                            </a>
                        )}
                    </div>
                </div>
            }
        </div>

    );
};

export const NewPartnersBlockInfo: TypedBlockTypeInfo<NewPartnersBlockElement> = {
    id: "NewPartnersBlock",
    name: "NewPartnersBlock",
    preview: preview,
    renderer: NewPartnersBlock,
    initialData: {
        title: "string",
        elements: [{
            name: "string",
            description: "string",
            logo: null,
            price: "",
            boldTextPrice: ""
        }],
        bottomText: "string",
        buttons: [{
            buttonText: "string",
            buttonLink: "#"
        }],
        showButtons: false,
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            button: {
                fields: [
                    {
                        id: "buttonText",
                        type: "String",
                        name: "Button Text"
                    },
                    {
                        id: "buttonLink",
                        type: "String",
                        name: "Button Link"
                    }
                ]
            },
            element: {
                fields: [
                    {
                        id: "name",
                        type: "String",
                        name: "Name"
                    },
                    {
                        id: "description",
                        type: "String",
                        name: "Description"
                    },
                    {
                        id: "logo",
                        type: "Custom",
                        customType: "Image",
                        name: "Logo",
                    },
                    {
                        id: "price",
                        type: "String",
                        name: "Price"
                    },
                    {
                        id: "boldTextPrice",
                        type: "String",
                        name: "Bold Text Price"
                    },
                ],
            },
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
            {
                id: "showButtons",
                type: "CheckBox",
                name: "Show Buttons"
            },
            {
                id: "bottomText",
                type: "String",
                name: "Bottom Text",
            },
            {
                id: "buttons",
                type: "List",
                listType: "button",
                name: "Buttons",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};
