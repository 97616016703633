import React, { useEffect, useState } from "react";
import style from "./LineAndImageBlock.module.css";

interface CurveTextProps {
    text: string;
    color: string;
}

export const CurveText1: React.FC<CurveTextProps> = ({ text, color }) => {
    const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
    const id = `curved${Math.random() * 100}`;

    return (
        <div className={style.svgContainer}>
            <svg width="100%" height="auto" viewBox="0 0 715 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    id={id}
                    d="M359.875 58C500.875 74 558.322 337.929 374.875 430.5C54.4725 592.181 20.9808 737.983 84.5837 864.058C131.51 957.076 238.066 1004.47 329.364 954.281C363.566 935.478 398.448 910.009 428.875 878C514.471 778.296 566.875 555.5 628.875 430.5C710.998 264.928 811.875 112 960.375 80"
                    stroke="white"
                    strokeWidth={"115"}
                    transform="translate(-250, -250)"
                />
                <text fontSize={"2.625rem"} fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={color}>
                    <textPath href={`#${id}`} textAnchor="start">
                        {repeatedText}
                    </textPath>
                </text>
            </svg>
        </div>
    );
};

