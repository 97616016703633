// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loginPage_loginBoard__1gD5o{\n  border: 1px solid black;\n  padding: 50px;\n  background: white;\n  border-radius: 6px;\n}\n\n.loginPage_loginBoard__input__2oaUB{\n  width: 100%;\n  padding: 0 15px;\n  font-size: 14px;\n  line-height: 36px;\n  background: #FFFFFF;\n  border: 2px solid #EFF3FA;\n  box-sizing: border-box;\n  border-radius: 3px;\n}\n\n.loginPage_loginBoard__input__2oaUB::placeholder{\n  color: #646F83;\n}\n\n.loginPage_loginBoard__input__2oaUB:focus{\n  outline: none;\n  border: 2px solid #567DD0;\n}\n", "",{"version":3,"sources":["webpack://src/pages/LoginPage/loginPage.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AACpB;;AAGA;EACE,WAAW;EACX,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":[".loginBoard{\n  border: 1px solid black;\n  padding: 50px;\n  background: white;\n  border-radius: 6px;\n}\n\n\n.loginBoard__input{\n  width: 100%;\n  padding: 0 15px;\n  font-size: 14px;\n  line-height: 36px;\n  background: #FFFFFF;\n  border: 2px solid #EFF3FA;\n  box-sizing: border-box;\n  border-radius: 3px;\n}\n\n.loginBoard__input::placeholder{\n  color: #646F83;\n}\n\n.loginBoard__input:focus{\n  outline: none;\n  border: 2px solid #567DD0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginBoard": "loginPage_loginBoard__1gD5o",
	"loginBoard__input": "loginPage_loginBoard__input__2oaUB"
};
export default ___CSS_LOADER_EXPORT___;
