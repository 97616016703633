// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".htmlWithIconBlock_block__3gdw1 > div {\n  margin: 10px 0 20px;\n}\n\n.htmlWithIconBlock_block__3gdw1 h3, .htmlWithIconBlock_block__3gdw1 a {\n  font-size: 1rem;\n  color: #01359a;\n  text-align: left;\n  margin-bottom: 30px;\n}\n\n.htmlWithIconBlock_block__3gdw1 img {\n  margin-right: 20px;\n}\n\n@media screen and (max-width: 1023px) {\n  .htmlWithIconBlock_block__3gdw1 {\n    flex-direction: column;\n    align-items: center !important;\n  }\n\n  .htmlWithIconBlock_block__3gdw1 img {\n    margin-right: 0;\n    margin-bottom: 10px;\n  }\n\n  .htmlWithIconBlock_block__3gdw1 h3 {\n    text-align: center;\n  }\n}\n", "",{"version":3,"sources":["webpack://../components/src/blocks/HtmlWithIconBlock/htmlWithIconBlock.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAGA;EACE;IACE,sBAAsB;IACtB,8BAA8B;EAChC;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".block > div {\n  margin: 10px 0 20px;\n}\n\n.block h3, .block a {\n  font-size: 1rem;\n  color: #01359a;\n  text-align: left;\n  margin-bottom: 30px;\n}\n\n.block img {\n  margin-right: 20px;\n}\n\n\n@media screen and (max-width: 1023px) {\n  .block {\n    flex-direction: column;\n    align-items: center !important;\n  }\n\n  .block img {\n    margin-right: 0;\n    margin-bottom: 10px;\n  }\n\n  .block h3 {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": "htmlWithIconBlock_block__3gdw1"
};
export default ___CSS_LOADER_EXPORT___;
