// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".grid_col-12__2IwlI{\n  width: 100%;\n}\n\n.grid_col-11__1MEF0{\n  width: 91.6666666667%;\n}\n\n.grid_col-10__-lZ2L{\n  width: 83.3333333333%;\n}\n\n.grid_col-9__3w3Cm{\n  width: 75%;\n}\n\n.grid_col-8__3U6EE{\n  width: 66.6666666667%;\n}\n\n.grid_col-7__1k05M{\n  width: 58.3333333333%;\n}\n\n.grid_col-6__2oMNf{\n  width: 50%;\n}\n\n.grid_col-5__2xqYw{\n  width: 41.6666666667%;\n}\n\n.grid_col-4__6Y7-N{\n  width: 33.3333333333%;\n}\n\n.grid_col-3__2Qi32{\n  width: 25%;\n}\n\n.grid_col-2__2jscx{\n  width: 16.6666666667%;\n}\n\n.grid_col-1__H6Kbn{\n  width: 8.33333333333%;\n}\n\n@media(max-width: 1023px) {\n  .grid_col-1__H6Kbn,.grid_col-2__2jscx,.grid_col-3__2Qi32,.grid_col-4__6Y7-N,.grid_col-5__2xqYw,.grid_col-6__2oMNf,.grid_col-7__1k05M,.grid_col-8__3U6EE,.grid_col-9__3w3Cm,.grid_col-10__-lZ2L,.grid_col-11__1MEF0{\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://../components/src/styles/grid.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".col-12{\n  width: 100%;\n}\n\n.col-11{\n  width: 91.6666666667%;\n}\n\n.col-10{\n  width: 83.3333333333%;\n}\n\n.col-9{\n  width: 75%;\n}\n\n.col-8{\n  width: 66.6666666667%;\n}\n\n.col-7{\n  width: 58.3333333333%;\n}\n\n.col-6{\n  width: 50%;\n}\n\n.col-5{\n  width: 41.6666666667%;\n}\n\n.col-4{\n  width: 33.3333333333%;\n}\n\n.col-3{\n  width: 25%;\n}\n\n.col-2{\n  width: 16.6666666667%;\n}\n\n.col-1{\n  width: 8.33333333333%;\n}\n\n@media(max-width: 1023px) {\n  .col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11{\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"col-12": "grid_col-12__2IwlI",
	"col-11": "grid_col-11__1MEF0",
	"col-10": "grid_col-10__-lZ2L",
	"col-9": "grid_col-9__3w3Cm",
	"col-8": "grid_col-8__3U6EE",
	"col-7": "grid_col-7__1k05M",
	"col-6": "grid_col-6__2oMNf",
	"col-5": "grid_col-5__2xqYw",
	"col-4": "grid_col-4__6Y7-N",
	"col-3": "grid_col-3__2Qi32",
	"col-2": "grid_col-2__2jscx",
	"col-1": "grid_col-1__H6Kbn"
};
export default ___CSS_LOADER_EXPORT___;
