import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./newFounderBlock.module.css";
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import { HtmlPresenter } from "../../ui/HtmlPresenter/htmlPresenter";

export interface NewFounderBlockElement {
  backgroundColor?: "pink" | "blue";
  textSide?: "left" | "right";
  title: string;
  founder: string,
  text: string;
  signature: number | null;
  img: number | null;
  topMargin?: string;
  bottomMargin?: string;
  customMargin: boolean;
}

export const NewFounderBlock = (props: NewFounderBlockElement) => {
  const backgroundClass = props.backgroundColor === "blue" ? styles.blueBackground : styles.defaultBackground;
  const flexDirectionClass = props.textSide === "right" ? "lg:flex-row-reverse" : "lg:flex-row";
  const marginTop = props.customMargin ? props.topMargin || "0" : "0";
  const marginBottom = props.customMargin ? props.bottomMargin || "0" : "0";
  return (
    <div style={{
      backgroundColor: props.backgroundColor === "blue" ? "#00309D" : "#F3D7E3",
      marginTop: `${marginTop}px` || "0",
      marginBottom: `${marginBottom}px` || "0"
    }}>
      <div className={`flex flex-col justify-between mx-auto w-full max-w-screen-xl ${flexDirectionClass} ${styles.mobileFounder}`}>
        <div className={`${styles.newFounderBlock__founderInfo} ${backgroundClass}`}
          style={props.backgroundColor === "blue" ? { backgroundColor: "#00309D" } : { backgroundColor: "#F3D7E3" }}>
          <div className={styles.newFounderBlock__textInfo} >
            <div className={styles.newFounderBlock__text} style={props.backgroundColor === "blue" ? { color: "#FFFFFF" } :
              { color: "#373737" }}>{props.title}</div>
            <div className={styles.newFounderBlock__founderName} style={props.backgroundColor === "blue" ? { color: "#FFFFFF" } :
              { color: "#373737" }}>{props.founder}</div>
            <HtmlPresenter text={props.text} />
            {props.signature && (
              <div className={styles.newFounderBlock__signatureContainer}>
                <img className={styles.newFounderBlock__signature} src={`${ApiBaseUrl}/api/media/${props.signature}`} alt="" />
              </div>
            )}
          </div>
        </div>
        {props.img && <img className="w-full lg:w-6/12" src={`${ApiBaseUrl}/api/media/${props.img}`} alt="" />}
      </div>
    </div>
  );
};

export const NewFounderBlockInfo: TypedBlockTypeInfo<NewFounderBlockElement> = {
  id: "NewFounderBlock",
  name: "NewFounderBlock",
  preview: preview,
  renderer: NewFounderBlock,
  initialData: {
    backgroundColor: "pink",
    textSide: "left",
    title: "string",
    founder: "string",
    text: "string",
    signature: null,
    img: null,
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
  },
  definition: {
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "founder",
        type: "String",
        name: "Founder",
      },
      {
        id: "text",
        type: "Custom",
        customType: "Html",
        name: "Text",
      },
      {
        id: "signature",
        type: "Custom",
        customType: "Image",
        name: "Signature",
      },
      {
        id: "img",
        type: "Custom",
        customType: "Image",
        name: "Img",
      },
      {
        id: "backgroundColor",
        type: "Radio",
        name: "Background Color",
        possibleValues: [
          {
            id: "pink",
            name: "pink",
          },
          {
            id: "blue",
            name: "blue",
          },
        ],
      },
      {
        id: "textSide",
        type: "Radio",
        name: "Text Side",
        possibleValues: [
          {
            id: "left",
            name: "left",
          },
          {
            id: "right",
            name: "right",
          },
        ],
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ],
  },
};
