import React, { FC } from "react";
import { useObserver } from "mobx-react";
import { AdminInputBox } from "src/components/common/AdminInputBox";
import { GlobalLink, GlobalSocialLink } from "../common/commonGlobal";
import { GlobalSettingsPageStore } from "../../../../stores/pages/adminStores/globalSettings/globalSettingsPageStore";

export const CatalogSettings: FC<{ s: GlobalSettingsPageStore }> = ({ s }) => {
  return useObserver(() => (
    <div>
      <AdminInputBox
        value={s.catalogUniversityTitle}
        label="Title university"
        onChange={(e) => (s.catalogUniversityTitle = e.target.value)}
      />
      <AdminInputBox
        value={s.catalogUniversityDescription}
        label="Description university"
        onChange={(e) => (s.catalogUniversityDescription = e.target.value)}
      />
      <AdminInputBox
        value={s.catalogUniversitySearch}
        label="University Search"
        onChange={(e) => (s.catalogUniversitySearch = e.target.value)}
      />
      <AdminInputBox
        value={s.catalogHousingTitle}
        label="Title housing"
        onChange={(e) => (s.catalogHousingTitle = e.target.value)}
      />
      <AdminInputBox
        value={s.catalogHousingDescription}
        label="Description housing"
        onChange={(e) => (s.catalogHousingDescription = e.target.value)}
      />
      <AdminInputBox
        value={s.catalogHousingSearch}
        label="Housing Search"
        onChange={(e) => (s.catalogHousingSearch = e.target.value)}
      />
      <AdminInputBox
        value={s.catalogCourseTitle}
        label="Title course"
        onChange={(e) => (s.catalogCourseTitle = e.target.value)}
      />
      <AdminInputBox
        value={s.catalogCourseDescription}
        label="Description course"
        onChange={(e) => (s.catalogCourseDescription = e.target.value)}
      />
      <AdminInputBox
        value={s.catalogCourseSearch}
        label="Course Search"
        onChange={(e) => (s.catalogCourseSearch = e.target.value)}
      />
      <AdminInputBox
        value={s.catalogSearchButton}
        label="Button Search"
        onChange={(e) => (s.catalogSearchButton = e.target.value)}
      />
    </div>
  ));
};
