import React, { FC } from "react";
import { useObserver } from "mobx-react";
import { AdminInputBox } from "src/components/common/AdminInputBox";
import { GlobalSettingsPageStore } from "../../../../stores/pages/adminStores/globalSettings/globalSettingsPageStore";

export const KnowledgeBaseSettings: FC<{ s: GlobalSettingsPageStore }> = ({ s }) => {
  return useObserver(() => (
    <div>
      <AdminInputBox
        value={s.knowledgeBaseTitle}
        label="Title"
        onChange={(e) => (s.knowledgeBaseTitle = e.target.value)}
      />
      <AdminInputBox
        value={s.knowledgeBaseDescription}
        label="Description"
        onChange={(e) => (s.knowledgeBaseDescription = e.target.value)}
      />
       <AdminInputBox
        value={s.knowledgeBaseSearchInput}
        label="Search input"
        onChange={(e) => (s.knowledgeBaseSearchInput = e.target.value)}
      />
      <AdminInputBox
        value={s.knowledgeBaseSearchButton}
        label="Search button"
        onChange={(e) => (s.knowledgeBaseSearchButton = e.target.value)}
      />
      <AdminInputBox
        value={s.knowledgeBaseDescriptionTestCard}
        label="Description Test Card"
        onChange={(e) => (s.knowledgeBaseDescriptionTestCard = e.target.value)}
      />
      <AdminInputBox
        value={s.knowledgeBaseCurveTestCard}
        label="Curve Test Card"
        onChange={(e) => (s.knowledgeBaseCurveTestCard = e.target.value)}
      />
      <AdminInputBox
        value={s.knowledgeBaseButtonTestCard}
        label="Button Test Card"
        onChange={(e) => (s.knowledgeBaseButtonTestCard = e.target.value)}
      />
      <AdminInputBox
        value={s.knowledgeBaseLinkTestCard}
        label="Link Test Card"
        onChange={(e) => (s.knowledgeBaseLinkTestCard = e.target.value)}
      />
    </div>
  ));
};
