import React from "react";
import style from "./LineAndImageBlock.module.css";

interface CurveTextProps {
    text: string;
    color: string;
}

export const CurveText3: React.FC<CurveTextProps> = ({ text, color }) => {
    const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0"}`.repeat(10);
    const id = `curved${Math.random() * 100}`;

    return (
        <div className={style.svgContainer}>
            <svg width="100%" height="auto" viewBox="0 0 710 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    id={id}
                    d="M52 25C72.3999 68.2478 137.19 176.139 195.275 265.286C241.17 335.724 343.763 412.551 406.286 468.752V468.752C507.127 559.397 520.581 700.004 408.38 776.135C391.3 787.724 373.043 795.139 354.5 797C220 810.5 139 738.5 110.5 695"                    
                    stroke="white"
                    stroke-width="115"
                    transform="translate(-170, 100)"
                />
                <text fontSize="2.625rem" fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill={color}>
                    <textPath href={`#${id}`} textAnchor="start">
                        {repeatedText}
                    </textPath>
                </text>
            </svg>
        </div>
    );
};